import { nginx_https_host } from '../../../..'
import Fetcher from '../../../../classes/Fetcher'
import Toaster from '../../../../classes/Toaster'
import { myMenuAvatar } from '../../../../globals/dom'

let myEditForm, mySubmitButton, myEmailField, myImageField
let myOldPwd, myNewPwd1, myNewPwd2
let myFetcher, myReader, myToaster
let changedImage, changedEmail, changedOldPwd, changedNewPwd
let myPasswordInputTogglers

const onSubmitButton = (e) => {
  e.preventDefault()
  let data = {}
  //TODO validations on attempt to submit
  if (changedImage) {
    let myAvatarUrl = myImageField.style.backgroundImage.toString()
    let myTrimedAvatarUrl = myAvatarUrl.substr(
      myAvatarUrl.indexOf('"') + 1,
      myAvatarUrl.length
    )
    myTrimedAvatarUrl = myTrimedAvatarUrl.substr(
      0,
      myTrimedAvatarUrl.length - 2
    )
    data['avatar'] = myTrimedAvatarUrl
  }
  if (changedEmail) {
    data['email'] = myEmailField.value
  }
  if (changedNewPwd) {
    data['new_password1'] = myNewPwd1.value
    data['new_password2'] = myNewPwd2.value

    data['old_password'] = myOldPwd.value

    if (data['old_password'].length < 1) {
      myToaster = new Toaster(
        '<div>You need to provide your current password in order to change it to a new one</div>'
      )
      return
    }

    if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(data['new_password1'])) {
      myToaster = new Toaster(
        '<div><b>Password requeriments</b></div><ul style="list-style:none;padding:0;margin:1rem 0 0 0"><li>At least 8 characters long</li><li>At least one upper case letter</li><li>At least one lower case letter</li><li>At least one number</li></ul>'
      )
      return
    }

    if (data['new_password1'] != data['new_password2']) {
      myToaster = new Toaster(
        'Submitted profile new password do not match',
        3500
      )
      return
    }
  }

  const url = 'api/profile/'
  myFetcher.postAuthData(url, data).then((res) => {
    // console.dir('TEST -> respone from server is ', res)
    const objkeys = Object.keys(res)

    if (objkeys.includes('errormsg')) {
      myToaster = new Toaster(res.errormsg.toString(), 3500)
      return
    }

    myToaster = new Toaster('Submitted Profile Succesful :)', 3500)

    if (objkeys.includes('avatar')) {
      const myCurrentLocalStorage = JSON.parse(
        localStorage.getItem('transcendence')
      )
      myCurrentLocalStorage.avatar = res.avatar
      myMenuAvatar.style.backgroundImage = 'url("' + res.avatar + '")'
      localStorage.setItem(
        'transcendence',
        JSON.stringify(myCurrentLocalStorage)
      )
    }
    infoeditScreenDestroy()
    infoeditScreenInit()
  })
}

const placeImage = (e) => {
  myImageField.style.backgroundImage = `url('${e.target.result}')`
}

const setNewImage = () => {
  const myNewImage = myEditForm.querySelector('input[type=file]').files[0]

  myReader.addEventListener('load', placeImage, false)

  myReader.readAsDataURL(myNewImage)
}

const onFormChange = (e) => {
  switch (e.target.id) {
    case 'formFile':
      if (
        e.target.files[0].type.match('image/jpeg') ||
        e.target.files[0].type.match('image/png')
      ) {
        if (e.target.files[0].size > 200000) {
          myToaster = new Toaster(
            'Image too big. Please do not upload images bigger than 200Kb',
            3500
          )
        } else {
          myEditForm.querySelector('span').textContent = e.target.files[0].name
          setNewImage()
          changedImage = true
        }
      } else {
        myToaster = new Toaster(
          'Not a valid image format. Please upload only png or jpeg images',
          3500
        )
      }
      break
    case 'email':
      changedEmail = true
      break
    case 'old_password':
      changedOldPwd = true
      break
    case 'new_password1':
      changedNewPwd = true
      break
    case 'new_password2':
      changedNewPwd = true
      break
    default:
      null
  }
}

export const infoeditScreenInit = () => {
  // ? set DOM references

  myEditForm = document.getElementById('edit-form')
  mySubmitButton = myEditForm.querySelector('button')
  myEmailField = myEditForm.querySelector('#email')
  myImageField = myEditForm.querySelector('.avatar-image')
  myOldPwd = myEditForm.querySelector('#old_password')
  myNewPwd1 = myEditForm.querySelector('#new_password1')
  myNewPwd2 = myEditForm.querySelector('#new_password2')

  // ? Reset all password fields

  myOldPwd.removeAttribute('hidden')
  myNewPwd1.removeAttribute('hidden')
  myNewPwd2.removeAttribute('hidden')

  // ? Reset all password inputs visibility here

  myPasswordInputTogglers = myEditForm.querySelectorAll(
    'div.password-input-toggler'
  )

  myPasswordInputTogglers.forEach((_obj) => {
    _obj.classList.remove('show')
    _obj.classList.add('hide')
    _obj.parentNode.querySelector('input').type = 'password'
  })

  let transcendence_item = localStorage.getItem('transcendence')

  if (transcendence_item) {
    let transcendence_json = JSON.parse(transcendence_item)
    if ('username' in transcendence_json) {
      if (transcendence_json.username.startsWith('42')) {
        myOldPwd.setAttribute('hidden', '')
        myNewPwd1.setAttribute('hidden', '')
        myNewPwd2.setAttribute('hidden', '')
      }
    }
  }

  myEditForm.reset() // ? Reset the form on screen load
  myEditForm.querySelector('span').textContent = 'No file choosen' // ? Reset the file name

  myReader = new FileReader()
  changedImage = changedEmail = changedOldPwd = changedNewPwd = false

  myFetcher = new Fetcher()

  myFetcher.getAuthData(nginx_https_host + '/api/profile/').then((res) => {
    let resKeys = Object.keys(res)
    if (resKeys.includes('email')) myEmailField.value = res['email']
    if (resKeys.includes('avatar'))
      myImageField.style.backgroundImage = `url('${res['avatar']}')`
  })

  myEditForm.addEventListener('change', onFormChange, false)

  // ? listen clicks on the send button
  mySubmitButton.addEventListener('click', onSubmitButton, false)
}

export const infoeditScreenDestroy = () => {
  mySubmitButton.removeEventListener('click', onSubmitButton, false)
  myEditForm.removeEventListener('change', onFormChange, false)

  myEditForm = mySubmitButton = myReader = myFetcher = null
  myPasswordInputTogglers = myOldPwd = myNewPwd1 = myNewPwd2 = null
  changedImage = changedEmail = changedOldPwd = changedNewPwd = false
}
