import { nginx_https_host } from '../../../..'
import Fetcher from '../../../../classes/Fetcher'

let friendsList
let myFetcher
let url
let newFriendId

const clickContainer = (e) => {
  let curEl = e.target
  let isListRow = curEl.classList.contains('friends-list-row-container')
  let isListContainer = curEl.classList.contains('friends-list-container')
  while (!isListRow && !isListContainer)
  {
    curEl = curEl.parentElement
    if (curEl)
    {
      isListRow = curEl.classList.contains('friends-list-row-container')
      isListContainer = curEl.classList.contains('friends-list-container')
    }
    else
    {
      isListContainer = true
      isListRow = false
    }
  }
  if (isListRow)
  {
    url = nginx_https_host+'/api/friends/remove/'
    let newFriendId = parseInt(curEl.dataset.friendId)
    myFetcher.postAuthData(url, {friends: [newFriendId]}).then((res) => {
      listScreenDestroy()
      listScreenInit()
    })
  }
}

export const listScreenInit = () => {
  friendsList = document.querySelector('#list .friends-list-container')
  friendsList.addEventListener("click", clickContainer)
  myFetcher = new Fetcher()
  url = nginx_https_host+'/api/friendslist/html/'

  myFetcher.getAuthDataHtml(url).then((res) => {
    friendsList.innerHTML = res;
  })
}

export const listScreenDestroy = () => {
  friendsList.removeEventListener("click", clickContainer)
  friendsList = null
  myFetcher = null
  url = null
}
