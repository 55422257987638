import Toaster from '../../../classes/Toaster'
import Fetcher from '../../../classes/Fetcher'
import { nginx_https_host, wsOnline } from '../../../index'

let myLoginButton, myToaster, data, myInputFields, mySignInForm, myFetcher

const onSubmitButton = (e) => {
  e.preventDefault()

  data = {}
  myInputFields.forEach((el, i) => {
    data[el.name] = el.value
  })
  let fieldsHaveValue = false

  Object.keys(data).forEach((el, i) => {
    if (data[el].length == 0) {
      myToaster = new Toaster('Please do not leave empty fields')
      fieldsHaveValue = false
      return
    }
    fieldsHaveValue = true
  })

  if (!fieldsHaveValue) return

  const url = nginx_https_host + '/api/login/'

  let isFormValid = true

  Object.keys(data).forEach((el, i) => {
    if (el == 'username') {
      if (!/^[A-Za-z][A-Za-z0-9_\-]{4,30}$/.test(data[el])) {
        myToaster = new Toaster(
          '<div><b>Username requeriments</b></div><ul style="list-style:none;padding:0;margin:1rem 0 0 0"><li>Must start with a letter</li><li>At least 5 characters long</li><li>Letters are valid</li><li>Numbers are valid</li><li>_ and - are also valid</li></ul>'
        )
        isFormValid = false
      }

      return
    } else if (el == 'password1') {
      if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(data[el])) {
        myToaster = new Toaster(
          '<div><b>Password requeriments</b></div><ul style="list-style:none;padding:0;margin:1rem 0 0 0"><li>At least 8 characters long</li><li>At least one upper case letter</li><li>At least one lower case letter</li><li>At least one number</li></ul>'
        )
        isFormValid = false
      }
      return
    }
  })

  if (isFormValid) {
    myToaster ? myToaster.closeToaster() : null

    myFetcher.postData(url, data).then((res) => {
      const obkeys = Object.keys(res)
      if (
        obkeys.includes('access') &&
        obkeys.includes('refresh') &&
        obkeys.includes('username') &&
        obkeys.includes('userid') &&
        obkeys.includes('avatar')
      ) {
        localStorage.setItem(
          'transcendence',
          JSON.stringify({
            access: res['access'],
            refresh: res['refresh'],
            username: res['username'],
            userid: res['userid'],
            avatar: res['avatar'],
          })
        )
        document.body.classList.remove('not-logged')
        document.body.classList.add('logged')

        document.querySelector(
          'section.app nav >li:first-of-type span a'
        ).textContent = res['username'].toString() // ? Set username on menu before shoing app section
        if (wsOnline) wsOnline.ws_init(res['username'])
        else console.error('wsOnline is', wsOnline)
        window.location.hash = ''
      } else {
        localStorage.removeItem('transcendence')
        myToaster = new Toaster(res.errormsg.toString()) // TODO recoger errores de dign in desde back end
      }
    })
  }
}

export const signinScreenInit = () => {
  mySignInForm = document.getElementById('signin-form')

  myInputFields = Array.from(mySignInForm.querySelectorAll('input'))

  // ? Reset password visibility

  mySignInForm
    .querySelector('div.password-input-toggler')
    .classList.remove('show')
  mySignInForm.querySelector('div.password-input-toggler').classList.add('hide')

  myLoginButton = document.querySelector('form#signin-form button')

  myFetcher = new Fetcher()

  mySignInForm.reset()

  myLoginButton.addEventListener('click', onSubmitButton, false)
}

export const signinScreenDestroy = () => {
  myLoginButton.removeEventListener('click', onSubmitButton, false)

  myLoginButton =
    myToaster =
    myInputFields =
    myFetcher =
    mySignInForm =
    data =
      null
}
