export default class Ball {
	constructor(_x, _y, _radius, _color)
	{
		this.x = _x;
		this.y = _y;
		this.radius = _radius;
		this.color = _color;
	}
	draw(ctx)
	{
		ctx.fillStyle = this.color;
		ctx.beginPath();
		ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
		ctx.fill();
		ctx.closePath();
	}
}