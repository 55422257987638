import { setAnchorRegister42Link } from './register42'

let mySplash, myShovels, myBall, myPongLetters, mySplashMenu, register42Anchor

const revealMenu = (e) => {
  if (!register42Anchor.getAttribute('href'))
    setAnchorRegister42Link(register42Anchor)

  mySplash.style.cursor = 'default' // ? Default behaviour for screen cursor

  mySplash.removeEventListener('click', revealMenu, false) // ? Stop listening for clicks

  myPongLetters.forEach((el, i) => {
    el.classList.remove('revealing-letter')
  }) // ? Hide PONG letters

  // ? Make shovles alittle smaller
  myShovels.classList.remove('showing-shovels')
  myShovels.classList.add('reducing-shovels')

  setTimeout(() => {
    mySplashMenu.classList.add('showing-menu-intro')

    // ? MAKE SURE LETTERS ARE HIDDEN AT THIS POINT
    myPongLetters.forEach((el, i) => {
      el.classList.remove('revealing-letter')
    })
  }, 1200) // ? Reveal menu
}

export const splashScreenInit = () => {
  // ? Assign values to local vars

  mySplash = document.getElementById('splash')

  myShovels = document.querySelector(
    'div#splash div.shovels-container div.shovels'
  )
  myBall = document.querySelector('div#splash div.ball-container')
  mySplashMenu = document.querySelector(
    'div#splash div.menu-intro-container ul'
  )

  myPongLetters = document.querySelectorAll(
    'div#splash div.letters-container div.letters ul li'
  )

  register42Anchor = mySplashMenu.querySelector('#register42')

  // ? Anim

  setTimeout(() => {
    myShovels.classList.add('showing-shovels')
  }, 100) // ? reveal shovels

  setTimeout(() => {
    myBall.classList.add('full')
  }, 1100) // ? reveal big ball

  setTimeout(() => {
    myPongLetters.forEach((el, i) => {
      el.classList.add('revealing-letter')
    })

    mySplash.style.cursor = 'pointer' // ? reveal whole screen as clickable
  }, 1500) // ? Reveal PONG letters

  mySplash.addEventListener('click', revealMenu, false) // ? listen for the click
}

export const splashScreenDestroy = () => {
  try {
    myPongLetters.forEach((el, i) => {
      el.classList.remove('revealing-letter')
    })

    mySplashMenu.classList.remove('showing-menu-intro')
    myShovels.classList.remove('reducing-shovels')
    myBall.classList.remove('full')
  } catch (err) {
    console.log(err)
  }

  myShovels = myBall = myPongLetters = mySplashMenu = null
}
