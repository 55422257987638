import Toaster from '../../../../classes/Toaster'
import { set_training } from '../../game/playing'
import gameSettings from '../../../../../config/game'
import { myWaitingRoomInfoText } from '../../../../globals/dom'
import { request_matchID } from '../../game/waiting'
import { resetTournInGameSettings, resetGameInGameSettings } from '../../game/waiting'

let myTrainingForm,
  mySubmitButton,
  myInputFields,
  myRangeSelector,
  myAiChecker,
  myToaster,
  data

const onAiCheckerChange = (e) => {
    const myTargetInput = Array.from(myInputFields)[1]

    myTargetInput.value = null

    if (!e.target.checked) {
      myTargetInput.placeholder = 'Player two name'
      myTargetInput.disabled = false
    } else {
      myTargetInput.disabled = true
      myTargetInput.value = 'The PONG Ai'
    }
  },
  onSlideChange = (e) => {
    e.target.parentNode.querySelector('h4 span').textContent = e.target.value
  },
  onSubmitButton = (e) => {
    e.preventDefault()

    let data = {}
    myInputFields.forEach((el, i) => {
      data[el.name] = el.value // ? This takes uplayerone and uplayertwo for the text input fields
    })

    let fieldsHaveValue = true

    Object.keys(data).forEach((el, i) => {
      if (data[el].length == 0) {
        myToaster = new Toaster('Please do not leave empty fields')
        fieldsHaveValue = false
      }
    })

    if (!fieldsHaveValue) return

    data['targetScore'] = parseInt(
      myRangeSelector.parentNode.querySelector('h4 span').textContent // ? Set the value for the game points after we make sure all input fields have text, points per game can still be the default 1
    )

    data['withAi'] = myAiChecker.checked

    myToaster ? myToaster.closeToaster() : null

    resetGameInGameSettings()
    resetTournInGameSettings()

    gameSettings.gameType = 'training'
    gameSettings.match_id = ''
    set_training(data) // initialization function in playing.js

    myWaitingRoomInfoText.innerHTML = '<h4>You started a training match</h4>'

    request_matchID()
    
    document.body.classList.add('waiting')
    document.location.hash = '/waiting'

  }

export const trainingScreenInit = () => {
  myTrainingForm = document.getElementById('training-form') // ? Get the whole form

  myInputFields = Array.from(
    myTrainingForm.querySelectorAll('input[type="text"]') // ? Get the text inputs
  )

  myRangeSelector = myTrainingForm.querySelector('input[type="range"]') // ? Get the range input

  myAiChecker = myTrainingForm.querySelector('input[type="checkbox"]') // ? get the checker for Ai

  // * The following may no be needed if we use ssr

  // * reset points per game to 1 again
  myRangeSelector.value = myRangeSelector.parentNode.querySelector(
    'h4 span'
  ).textContent = 1

  // * reset empty fields again

  myInputFields.map((_obj) => (_obj.value = ''))

  // * reset waiting room text

  myWaitingRoomInfoText.innerHTML = '<h4>This is the Waiting Room</h4>'

  myAiChecker.checked = false
  Array.from(myInputFields)[1].disabled = false

  mySubmitButton = myTrainingForm.querySelector('button') // ? get the submit button

  myRangeSelector.addEventListener('input', onSlideChange, false)

  myAiChecker.addEventListener('change', onAiCheckerChange, false)

  mySubmitButton.addEventListener('click', onSubmitButton, false) // ? Listen for clicks on submit button
}

export const trainingScreenDestroy = () => {
  mySubmitButton.removeEventListener('click', onSubmitButton, false)

  myRangeSelector.removeEventListener('input', onSlideChange, false)

  myAiChecker.removeEventListener('change', onAiCheckerChange, false)

  myTrainingForm =
    mySubmitButton =
    myInputFields =
    myRangeSelector =
    myToaster =
    myAiChecker =
    data =
      null
}
