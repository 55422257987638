import { streamAsyncIterator } from '../modules/utils/utils'

export default class Fetcher {
  async getAuthDataHtml(_url) {
    try {
      const response = await fetch(_url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('transcendence')).access
          }`,
        },
      })

      if (!response.ok) {
        if (response.status == 401) {
          localStorage.removeItem('transcendence')
          window.location.hash = '/splash/'
          return ''
        }
        throw new Error(`Failed to fetch data. Status: ${response.status}`)
      }

      let res = ''

      for await (const chunk of streamAsyncIterator(response.body))
        res += String.fromCharCode.apply(null, chunk)
      return res
    } catch (error) {
      console.error('Error fetching data:', error.message)
      throw error
    }
  }

  async getAuthData(_url) {
    try {
      const response = await fetch(_url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('transcendence')).access
          }`,
        },
      })

      if (!response.ok) {
        if (response.status == 401) {
          localStorage.removeItem('transcendence')
          window.location.hash = '/splash/'
          return ''
        }
        throw new Error(`Failed to fetch data. Status: ${response.status}`)
      }

      const json = await response.json()
      return json
    } catch (error) {
      console.error('Error fetching data:', error.message)
      throw error
    }
  }

  async getData(_url) {
    try {
      const response = await fetch(_url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (!response.ok) {
        if (response.status == 401) {
          console.log('Unauthorized user')
          localStorage.removeItem('transcendence')
          window.location.hash = '/splash/'
          return ''
        }
        throw new Error(`Failed to fetch data. Status: ${response.status}`)
      }

      const json = await response.json()
      return json
    } catch (error) {
      console.error('Error fetching data:', error.message)
      throw error
    }
  }

  async postAuthData(_url, data) {
    try {
      const response = await fetch(_url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('transcendence')).access
          }`,
        },
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data),
      })
      console.log(response)
      if (!response.ok) {
        console.log(response)
        if (response.status == 401) {
          localStorage.removeItem('transcendence')
          window.location.hash = '/splash/'
          return ''
        }
        throw new Error(`Failed to fetch data. Status: ${response.status}`)
      }

      const json = await response.json()
      console.log(json)
      if ('status' in json && json['status']==401)
      {
        localStorage.removeItem('transcendence')
        window.location.hash = '/splash/'
        return ''
      }
      return json

      // ? SUCCESS
    } catch (error) {
      console.error('Error fetching data:', error.message)
      throw error
    }
  }

  async postData(_url, data) {
    try {
      const response = await fetch(_url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data),
      })

      if (!response.ok) {
        if (response.status == 401) {
          localStorage.removeItem('transcendence')
          window.location.hash = '/splash/'
          return ''
        }
        throw new Error(`Failed to fetch data. Status: ${response.status}`)
      }

      const json = await response.json()
      return json

      // ? SUCCESS
    } catch (err) {
      console.log(err)
    }
  }
}
