var gameSettings;

export default gameSettings = {

	gameType : "",
	p1Name: null,
	p2Name: null,
	selfName: null,
	targetScore: null,
	match_id : "",
	withAi: false,
	tournament: null,
	round:0,
	tourn_winner: null,
	tourn_won: null,
	leave_ack: false,
	disablePlay: false,
	needDisablePlayNow: false
}
