import Fetcher from '../../../../classes/Fetcher'
import { nginx_https_host, wsOnline } from '../../../../index.js';

let myLogoutButton

const onLogOutButtonClick = (e) => {
  e.preventDefault()
  
  let myFetcher = new Fetcher()
  let url = nginx_https_host+'/api/logout/'

  myFetcher.postAuthData(url).then((res) => {
      const obkeys = Object.keys(res)
	  if (obkeys.includes('errormsg'))
	  {
		  console.log(res.errormsg.toString())
		  return 
	  }
	  myLogoutButton.removeEventListener('click', onLogOutButtonClick, false)

	  localStorage.removeItem('transcendence')
    if (wsOnline)
    {
      wsOnline.ws_close()
    }
    document.body.classList.remove('logged')
	  document.body.classList.add('not-logged')
	  window.location.hash = ''
  })
}

export const confirmScreenInit = () => {
  myLogoutButton = document.querySelector('div#logout button')

  myLogoutButton.addEventListener('click', onLogOutButtonClick, false)
}

export const confirmScreenDestroy = () => {
  myLogoutButton = null
}
