import {
  updateGameState,
  recieveMatchID,
  updateStartTimer,
  updatePlayerJoined,
} from '../modules/sections/game/playing.js'
import {
  goToPlaying,
  updateGameAbort,
  updateRivalAbort,
  updateTournAbort,
  updateTournCont,
  createdTournMatch,
  initNewRound,
  finishTourn,
  playerGiveupTourn,
  joinedTourn,
  goToOverview,
} from '../modules/sections/game/waiting.js'
import { nginx_wss_host } from '../index.js'
import gameSettings from '../../config/game.js'

const on_msg = (data) => {
  const data_msg = data['msg']
  if (data_msg['primary'])
    console.log('the message sent is:', data_msg['primary'])
  if (data_msg['disconnect'])
    console.log('disconnection of user:', data_msg['disconnect'])
}

const on_user_disconnects_game = (data, datakeys) => {
  let data_info = data['user_disconnects']
  if (
    datakeys.includes('match_id') &&
    gameSettings.match_id == data_info['match_id']
  )
    if (datakeys.includes('username')) {
      let dis_username = data_info['username']
      if (gameSettings.selfName == dis_username) updateGameAbort(data)
      else if (
        gameSettings.p1Name == dis_username ||
        gameSettings.p2Name == dis_username
      )
        updateRivalAbort(data)
    }
}

const on_user_disconnects_tourn = (data, datakeys) => {
  let data_info = data['user_disconnects']
  if (
    datakeys.includes('tournament_id') &&
    gameSettings.tournament == data_info['tournament_id']
  )
    if (datakeys.includes('username')) {
      let dis_username = data_info['username']
      if (gameSettings.selfName == dis_username)
        updateTournAbort(data['user_disconnects'])
      else updateTournCont(data['user_disconnects'])
    }
}

const on_user_disconnects = (data) => {
  let data_info = data['user_disconnects']
  let datakeys = Object.keys(data_info)
  if (datakeys.includes('origin')) {
    if (data_info['origin'] == 'game') on_user_disconnects_game(data, datakeys)
    if (data_info['origin'] == 'tournament')
      on_user_disconnects_tourn(data, datakeys)
  }
}

const on_gamedata = (data) => {
  switch (data['gamedata']) {
    case 'game_state':
      updateGameState(data)
      break
    case 'game_abort':
      updateGameAbort(data)
      break
    case 'matchID':
      recieveMatchID(data)
      break
    case 'timer':
      updateStartTimer(data['content'])
      break
    case 'player_joined':
      updatePlayerJoined(data['content'])
      break
    case 'ready':
      goToPlaying()
      break
    case 'aborted':
      goToOverview()
      break
    case 'error':
      console.log('error:', data['content'])
      // Exit the game screen
      if (document.body.classList.contains('playing')) {
        document.body.classList.remove('playing')
        window.location.hash = '/profile/#/overview'
      }
      break
    default:
      console.log('unhandled message type:', data['type'])
      break
  }
}

const on_tourndata = (data) => {
  if (data['tourndata'] == 'created_tourn_match' && 'content' in data)
    createdTournMatch(data['content'])
  else if (data['tourndata'] == 'finished_round' && 'content' in data)
    initNewRound(data['content'])
  else if (data['tourndata'] == 'finished_tourn' && 'content' in data)
    finishTourn(data['content'])
  else if (data['tourndata'] == 'player_joined' && 'content' in data)
    joinedTourn(data['content'])
  else if (data['tourndata'] == 'player_giveup' && 'content' in data)
    playerGiveupTourn(data['content'])
  else console.log('on message receiving tourndata else', data)
}

export default class WebSocketCon {
  websocket

  ws_init(username) {
    let usrname = username
    if (!this.websocket) {
      const base_url = nginx_wss_host
      this.websocket = new WebSocket(
        base_url + '/api/ws/' + username + '/?username=' + username
      )
    }

    if (this.websocket) {
      this.websocket.onopen = (event) => {
        let data = localStorage.getItem('transcendence')
        data = JSON.parse(data)
        const logged =
          data && data['access'] && data['refresh'] && data['username']
        this.websocket.send(
          JSON.stringify({ msg: 'Client sends Welcome from ' + logged })
        )
      }

      this.websocket.onmessage = (event) => {
        let data = JSON.parse(event.data)
        let datakeys = Object.keys(data)
        if (datakeys.includes('msg')) on_msg(data)
        else if (datakeys.includes('user_disconnects'))
          on_user_disconnects(data)
        else if (datakeys.includes('gamedata')) on_gamedata(data)
        else if (datakeys.includes('tourndata')) on_tourndata(data)
      }

      this.websocket.onclose = (event) => {
        this.ws_close()
        document.body.classList.remove('logged')
        document.body.classList.add('not-logged')
        window.location.hash = ''
      }
    }
  }

  ws_send(data) {
    if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.send(JSON.stringify(data))
    } else {
      console.error('WebSocket connection is not open')
    }
  }

  ws_close() {
    if (this.websocket) {
      this.websocket.close()
      this.websocket = null
    }
  }
}
