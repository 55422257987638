import { nginx_https_host } from '../../../..'
import Fetcher from '../../../../classes/Fetcher'

let history, myFetcher, url

export const historyScreenInit = () => {
  history = document.getElementById('history')
  myFetcher = new Fetcher()
  url = nginx_https_host+'/api/scheduling/match/html/'

  myFetcher.getAuthDataHtml(url).then((res) => {
    history.innerHTML = res;
  })
}

export const historyScreenDestroy = () => {
  history = myFetcher = url = null
}
