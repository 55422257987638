import Toaster from '../../../../classes/Toaster'
import Fetcher from '../../../../classes/Fetcher'
import { set_match } from '../../game/playing.js'
import { nginx_https_host } from '../../../../index.js'
import { request_matchID } from '../../game/waiting'
import { resetTournInGameSettings, resetGameInGameSettings } from '../../game/waiting'

let myMainDiv, mySubmitButton, myToaster, myFetcher

const joinPublic = (e) => {
  resetGameInGameSettings()
  resetTournInGameSettings()
  let url = nginx_https_host + '/api/scheduling/match/public/'
  myFetcher.postAuthData(url, {}).then((res) => {
    if ('errormsg' in res) {
      myToaster = new Toaster(
        'Game join to public failed with error: ' + res.errormsg
      )
      return
    }
    myToaster = new Toaster('Joined game with name ' + res.name, 1500)
    const match_data = {
      gameType: 'public',
      uplayerone: res.player1,
      uplayertwo: res.player2,
      targetScore: parseInt(res.win_points),
      match_id: res.name,
      withAi: false,
    }
    set_match(match_data) // initialization function in playing.js

    request_matchID()
    document.body.classList.add('waiting')
    document.location.hash = '/waiting'
  })
}

const onPublicGameClick = (e) => {
  e.preventDefault()
  joinPublic(e)
}

export const publicScreenInit = () => {
  myMainDiv = document.querySelector('div#public div')
  mySubmitButton = myMainDiv.querySelector('.public-button-container button')

  mySubmitButton.addEventListener('click', onPublicGameClick, false)
  myFetcher = new Fetcher()
}

export const publicScreenDestroy = () => {
  mySubmitButton.removeEventListener('click', onPublicGameClick, false)

  myToaster = myFetcher = myMainDiv = mySubmitButton = null
}
