import { nginx_https_host } from '../../../..'
import Fetcher from '../../../../classes/Fetcher'

let friendsSearch,
  myFetcher,
  url,
  searchstr = ' ',
  searchInput,
  mySearchListAddButtons,
  newFriendId


const onAddFriendClick = (e) => {
  let curEl = e.target.parentNode.parentNode.parentNode

  url = nginx_https_host+'/api/friends/add/'
  let newFriendId = parseInt(curEl.dataset.friendId, 10)
  myFetcher.postAuthData(url, { friends: [newFriendId] }).then((res) => {
    addScreenDestroy()
    addScreenInit()
  })
}

const chSearchInput = (e) => {
  e.target.value.length > 2 ? (searchstr = e.target.value) : (searchstr = ' ')

  addScreenDestroy()
  addScreenInit()
}

export const addScreenInit = () => {
  friendsSearch = document.querySelector('#add .search-list-container')

  while (friendsSearch.lastChild) {
    friendsSearch.removeChild(friendsSearch.lastChild)
  }

  searchInput = document.querySelector('#add input')
  searchInput.addEventListener('keyup', chSearchInput)

  myFetcher = new Fetcher()

  url = nginx_https_host+'/api/friendsearch/html/' + searchstr + '/'

  myFetcher.getAuthDataHtml(url).then((res) => {
    friendsSearch.innerHTML = res

    mySearchListAddButtons = document.querySelectorAll(
      'div.search-list-container div.search-list-row-container div.search-list-row div.search-username span'
    )
    mySearchListAddButtons.forEach((el, i) => {
      el.addEventListener('click', onAddFriendClick, false)
    })
  })
}

export const addScreenDestroy = () => {
  searchInput.removeEventListener('change', chSearchInput)
  if (mySearchListAddButtons) {
    mySearchListAddButtons.forEach((el, i) => {
      el.removeEventListener('click', onAddFriendClick, false)
    })
  }
  friendsSearch = searchInput = myFetcher = url = mySearchListAddButtons = null
}
