import Toaster from '../../../../classes/Toaster'
import Fetcher from '../../../../classes/Fetcher'
import { set_match } from '../../game/playing'
import { myWaitingRoomInfoText } from '../../../../globals/dom'
import { nginx_https_host } from '../../../..'
import { request_matchID } from '../../game/waiting'
import { resetTournInGameSettings, resetGameInGameSettings } from '../../game/waiting'

let myMainPrivateDiv,
  myPrivateDivs,
  myGameInputId,
  myPointsRangeSelector,
  mySubmitButtons,
  myGoBackButtons,
  myToaster,
  myFetcher

const onGoBackButtonClick = (e) => {
    if (
      e.currentTarget.parentNode.parentNode.classList.contains(
        'showing-create-form'
      ) ||
      e.currentTarget.parentNode.parentNode.classList.contains(
        'showing-join-form'
      )
    ) {
      e.currentTarget.parentNode.parentNode.classList.remove(
        'showing-create-form',
        'showing-join-form'
      )
    }
  },
  onSlideRangeChange = (e) => {
    e.target.parentNode.querySelector('h4 span').textContent = e.target.value
  },
  onDivClick = (e) => {
    if (e.currentTarget.parentNode.classList.contains('private-join-div')) {
      myMainPrivateDiv.classList.add('showing-join-form')
    } else {
      // ? CREATE PRIVATE GAME
      myMainPrivateDiv.classList.add('showing-create-form')
    }
  },
  createPrivate = (e) => {
    const myPoints = parseInt(
      myPointsRangeSelector.parentNode.querySelector('h4 span').textContent
    )
    resetGameInGameSettings()
    resetTournInGameSettings()
    let url = nginx_https_host+'/api/scheduling/match/create/'
    myFetcher.postAuthData(url, { points: myPoints }).then((res) => {
      if ('errormsg' in res) {
        myToaster = new Toaster(
          'Game creation of private failed with error: ' + res.errormsg
        )
        return
      }
      myToaster = new Toaster('Game created with private id ' + res.private_id, 1500)
      const match_data = {
        gameType: 'create_private',
        uplayerone: res.player1,
        uplayertwo: '',
        targetScore: parseInt(res.win_points),
        match_id: res.name,
        withAi: false
      }
      set_match(match_data) // initialization function in playing.js
      myWaitingRoomInfoText.innerHTML =
      `<h4>You created the private match ${match_data.match_id}</h4>
      <h4>The id for your match is ${res.private_id}</h4>
        <h6 style="margin:2rem 0 0 0; text-align:center;">Do not forget to send this Id to your partner!</h6>`

      request_matchID()
      document.body.classList.add('waiting')
      document.location.hash = '/waiting'
    })
  },
  joinPrivate = (e) => {
    resetGameInGameSettings()
    resetTournInGameSettings()
    let url = nginx_https_host+'/api/scheduling/match/join/' + myGameInputId.value
    myFetcher.postAuthData(url, {}).then((res) => {
      if ('errormsg' in res) {
        myToaster = new Toaster(
          'Game join to private failed with error: ' + res.errormsg
        )
        return
      }
      const match_data = {
        gameType: 'join_private',
        uplayerone: res.player1,
        uplayertwo: res.player2,
        targetScore: parseInt(res.win_points),
        match_id: res.name,
        withAi: false
      }
      set_match(match_data) // initialization function in playing.js
      myWaitingRoomInfoText.innerHTML = `<h4>You started the private match ${match_data.match_id}</h4>`
      request_matchID()
      document.body.classList.add('waiting')
      document.location.hash = '/waiting'
    })
  },
  onSubmitButtonClick = (e) => {
    e.preventDefault()

    // ? Get to know we are submitting the join or create form
    if (
      e.target.parentNode.parentNode.classList.value
        .split('-')
        .filter((cls) => cls.toLowerCase().includes('join')).length > 0
    ) {
      // ? submitting the join form with ID via myGameInputId.value
      if (myGameInputId.value == '') {
        myToaster = new Toaster('Please enter an Id value', 2500)
        return
      }
      joinPrivate(e)
    } else {
      // ? submitting the create form with number of points via myPointsRangeSelector.parentNode.querySelector('h4 span').textContent
      createPrivate(e)
    }
  }

export const privateScreenInit = () => {
  myMainPrivateDiv = document.getElementById('private')

  // * clear any previous added classes

  myMainPrivateDiv.classList.contains('showing-join-form')
    ? myMainPrivateDiv.classList.remove('showing-join-form')
    : null

  myMainPrivateDiv.classList.contains('showing-create-form')
    ? myMainPrivateDiv.classList.remove('showing-create-form')
    : null

  myPrivateDivs = myMainPrivateDiv.querySelectorAll('div h4')

  myGameInputId = myMainPrivateDiv.querySelector('input[type="text"]')
  myPointsRangeSelector = myMainPrivateDiv.querySelector('input[type="range"]')

  mySubmitButtons = myMainPrivateDiv.querySelectorAll('button')
  myGoBackButtons = myMainPrivateDiv.querySelectorAll(
    ' div.private-goback-indicator'
  )

  // * reset points per game to 1 again
  myPointsRangeSelector.value = myPointsRangeSelector.parentNode.querySelector(
    'h4 span'
  ).textContent = 1

  // * reset game input id texfield

  myGameInputId.value = ''

  // * reset waiting room text

  myWaitingRoomInfoText.innerHTML = '<h4>This is the Waiting Room</h4>'

  // * listen for events

  myPointsRangeSelector.addEventListener('input', onSlideRangeChange, false)

  myPrivateDivs.forEach((el, i) => {
    el.addEventListener('click', onDivClick, false)
  })

  myGoBackButtons.forEach((el, i) => {
    el.addEventListener('click', onGoBackButtonClick, false)
  })

  mySubmitButtons.forEach((el, i) => {
    el.addEventListener('click', onSubmitButtonClick, false)
  })

  myFetcher = new Fetcher()
}

export const privateScreenDestroy = () => {
  myPrivateDivs.forEach((el, i) => {
    el.removeEventListener('click', onDivClick, false)
  })

  mySubmitButtons.forEach((el, i) => {
    el.addEventListener('click', onSubmitButtonClick, false)
  })

  myPointsRangeSelector.removeEventListener('input', onSlideRangeChange, false)

  myMainPrivateDiv =
    myPrivateDivs =
    myGameInputId =
    mySubmitButtons =
    myPointsRangeSelector =
    myToaster =
    myGoBackButtons =
    myFetcher =
      null
}
