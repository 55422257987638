export default class Paddle
{
	constructor(_x, _y, _width, _height, _color)
	{
		this.x = _x;
		this.y = _y;
		this.width = _width;
		this.height = _height;
		this.color = _color;
	}
	
	draw(ctx)
	{
		let	xx = this.x - this.width / 2;
		let	yy = this.y - this.height / 2;
		ctx.fillStyle = this.color;
		ctx.fillRect(xx, yy, this.width, this.height);
	}
}