import Fetcher from '../../../classes/Fetcher'
import Toaster from '../../../classes/Toaster'
import { nginx_https_host, wsOnline } from '../../../index'

let mySignUpButton, myToaster, mySignUpForm, myInputFields, myFetcher, data, myContainer

const onSubmitButton = (e) => {
  e.preventDefault()
  data = {}
  myInputFields.forEach((el, i) => {
    data[el.name] = el.value
  })
  let fieldsHaveValue = false

  Object.keys(data).forEach((el, i) => {
    if (data[el].length == 0) {
      myToaster = new Toaster('Please do not leave empty fields')
      fieldsHaveValue = false
      return
    }
    fieldsHaveValue = true
  })

  if (!fieldsHaveValue) return

  const url = nginx_https_host + '/api/signup/'

  let isFormValid = true

  Object.keys(data).forEach((el, i) => {
    if (el == 'username') {
      if (!/^[A-Za-z][-\w]{4,10}$/.test(data[el])) {
        myToaster = new Toaster(
          '<div><b>Username requeriments</b></div><ul style="list-style:none;padding:0;margin:1rem 0 0 0"><li>Must start with a letter</li><li>Between 5 and 11 characters</li><li>Letters are valid</li><li>Numbers are valid</li><li>_ and - are also valid</li></ul>'
        )
        isFormValid = false
      }

      return
    } else if (el == 'email') {
      if (
        !/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(
          data[el]
        )
      ) {
        myToaster = new Toaster(
          '<div><b>Email requeriments</b></div><ul style="list-style:none;padding:0;margin:1rem 0 0 0"><li>Must be a valid email</li></ul>'
        )
        isFormValid = false
      }
      return
    } else if (el == 'password1') {
      if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(data[el])) {
        myToaster = new Toaster(
          '<div><b>Password requeriments</b></div><ul style="list-style:none;padding:0;margin:1rem 0 0 0"><li>At least 8 characters long</li><li>At least one upper case letter</li><li>At least one lower case letter</li><li>At least one number</li></ul>'
        )
        isFormValid = false
      }
      return
    } else if (el == 'password2') {
      if (data['password1'] != data['password2']) {
        myToaster = new Toaster(
          '<div><b>Password requeriments</b></div><ul style="list-style:none;padding:0;margin:1rem 0 0 0"><li>Password confirmation must be equal</li></ul>'
        )
        isFormValid = false
      }
      return
    }
  })

  if (isFormValid) {
    myToaster ? myToaster.closeToaster() : null

    myFetcher.postData(url, data).then((res) => {
      const obkeys = Object.keys(res)

      if (
        obkeys.includes('access') &&
        obkeys.includes('refresh') &&
        obkeys.includes('username') &&
        obkeys.includes('userid') &&
        obkeys.includes('avatar')
      ) {
        localStorage.setItem(
          'transcendence',
          JSON.stringify({
            access: res['access'],
            refresh: res['refresh'],
            username: res['username'],
            userid: res['userid'],
            avatar: res['avatar'],
          })
        )
        document.body.classList.remove('not-logged')
        document.body.classList.add('logged')
        document.querySelector(
          'section.app nav >li:first-of-type span a'
        ).textContent = res['username'].toString() // ? Set username on menu before shoing app section

        if (wsOnline) wsOnline.ws_init(res['username'])
        else console.error('wsOnline is', wsOnline)
        window.location.hash = ''
      } else {
        localStorage.removeItem('transcendence')
        myToaster = new Toaster(res.errormsg.toString())
      }
    })
  }
}

export const signupScreenInit = () => {
  mySignUpForm = document.getElementById('signup-form')

  // ? Reset all password input fields visibility

  mySignUpForm
    .querySelectorAll('div.password-input-toggler')
    .forEach((_el, _i) => {
      _el.classList.remove('show')
      _el.classList.add('hide')
    })

  myInputFields = Array.from(mySignUpForm.querySelectorAll('input'))

  mySignUpButton = mySignUpForm.querySelector('button')

  myFetcher = new Fetcher()

  mySignUpForm.reset()

  mySignUpButton.addEventListener('click', onSubmitButton, false)
}

export const signupScreenDestroy = () => {
  mySignUpButton.removeEventListener('click', onSubmitButton, false)

  mySignUpButton =
    myToaster =
    myInputFields =
    myFetcher =
    mySignUpForm =
    data =
      null
}
