import { nginx_https_host } from '../../../..'
import Fetcher from '../../../../classes/Fetcher'
import gameSettings from '../../../../../config/game'
import { myWaitingRoomInfoText } from '../../../../globals/dom'
import { resetGameInGameSettings, resetTournInGameSettings, goToWaiting } from '../../game/waiting'

let myTourmanentsListContainer, myToaster, myActionButtons, myFetcher

const onActionButtonClick = (e) => {
  const myTournmentId =
    e.target.parentNode.parentNode.parentNode.dataset.tournamentId
  const url = `${nginx_https_host}/api/scheduling/tournament/join_${myTournmentId}`

  resetTournInGameSettings()
  resetGameInGameSettings()
  myFetcher.postAuthData(url, {}).then((res) => {
    gameSettings.tournament = res.name
    myWaitingRoomInfoText.innerHTML = `<h4>You joined the tournament ${gameSettings.tournament}</h4>`
    gameSettings.disablePlay = true
    gameSettings.needDisablePlayNow = true
    gameSettings.leave_ack = false
    goToWaiting()
  })
}

export const joinScreenInit = () => {
  myTourmanentsListContainer = document.querySelector(
    '#join div.join-list-container'
  )
  myFetcher = new Fetcher()
  let url = `${nginx_https_host}/api/scheduling/tournament/join/html/`

  myFetcher.getAuthDataHtml(url).then((res) => {
    myTourmanentsListContainer.innerHTML = res

    myActionButtons = myTourmanentsListContainer.querySelectorAll(
      'div.join-list-row-container div.join-list-row div.right-side span'
    )

    myActionButtons.forEach((el, i) => {
      el.addEventListener('click', onActionButtonClick, false)
    })
  })
}

export const joinScreenDestroy = () => {
  myActionButtons.forEach((el, i) => {
    el.removeEventListener('click', onActionButtonClick, false)
  })
  myToaster = myFetcher = myActionButtons = myTourmanentsListContainer = null
}
