import { myMainNavButtons } from '../../globals/dom'

export const getActiveMainMenuLink = () => {
  const activeLiElement = Array.from(myMainNavButtons).find((li) =>
    li.classList.contains('active')
  )

  return activeLiElement
}

export const getActiveMainMenuSubMenu = () => {
  return document.querySelectorAll('nav li.active ul li')
}

export const revealStringByChar = (_text, _node, _speed) => {
  let textArr = _text.slice(0, _text.length),
    counter = 0

  const timer = setInterval(() => {
    if (counter == 0) {
      if (_node.textContent.length > 0) {
        _node.textContent = ''
        return
      }
    } // ? make sure textContent is null before trying to reveal new text
    if (counter < _text.length) {
      _node.textContent += textArr[counter]
      counter++
    } else {
      clearInterval(timer)
    }
  }, _speed)
}

export async function* streamAsyncIterator(stream) {
  const reader = stream.getReader()

  try {
    while (true) {
      const { done, value } = await reader.read()
      if (done) return
      yield value
    }
  } finally {
    reader.releaseLock()
  }
}

export const removeQueryString = () => {
  let current_url =
    window.location.origin + window.location.pathname + window.location.hash
  window.history.replaceState({ path: current_url }, '', current_url)
}

export const getWindowWidth = () => {
  return (
    window.innerWidth /
    parseFloat(
      window
        .getComputedStyle(document.getElementsByTagName('body')[0], null)
        .getPropertyValue('font-size')
    )
  )
}

export const setTogglePasswordVisibility = (_nodes) => {
  _nodes.forEach((node) => {
    node
      .querySelector('div.password-input-toggler')
      .addEventListener('click', (e) => {
        if (e.target.classList.contains('hide')) {
          e.target.classList.remove('hide')
          e.target.classList.add('show')
          e.target.parentNode.querySelector('input').type = 'text'
        } else {
          e.target.classList.remove('show')
          e.target.classList.add('hide')
          e.target.parentNode.querySelector('input').type = 'password'
        }
      })
  })
}
