import Ball from '../../../classes/Ball.js'
import Paddle from '../../../classes/Paddle.js'

import gameSettings from '../../../../config/game.js'

import { wsOnline } from '../../../index.js'

import { getWindowWidth } from '../../utils/utils.js'
import Toaster from '../../../classes/Toaster.js'
import { goToWaiting } from './waiting.js'
import { myWaitingRoomInfoText } from '../../../globals/dom'

let player1, player2, ballObj, startBool, startTimer, endBool, winner, myfont
let p1up, p1down, p2up, p2down, p1Score, p2Score, p1Name, p2Name, match_id

// ? New vars to take care of the resizing

let globalResEvent,
  globalResizeTimeout = null

// ? New functions to take care of the resizing

const resizeGlobalThrottler = (e) => {
  // ? ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!globalResizeTimeout) {
    globalResizeTimeout = setTimeout(() => {
      globalResizeTimeout = null
      globalResizer() // ? The resizer() will execute at a rate of 15fps
    }, 66)
  }
}

const globalResizer = () => {
  const myMobilePlayingButtons = document.querySelectorAll('#gaming div')

  if (getWindowWidth() < 90) {
    if (window.matchMedia('(orientation: landscape)').matches) {
      myMobilePlayingButtons.forEach((el, i) => {
        el.classList.add('showing-on-mobile')
      })
    } else {
      const myToaster = new Toaster('Please go landscape mode', 1800)
      myMobilePlayingButtons.forEach((el, i) => {
        el.classList.remove('showing-on-mobile')
      })
    }
  } else {
    myMobilePlayingButtons.forEach((el, i) => {
      el.classList.remove('showing-on-mobile')
    })
  }
}

// ? vars to hold mobile buttons

let myResponsiveButtons

const canvas = document.getElementById('game_canvas')
canvas.width = 1280
canvas.height = 720
const ctx = canvas.getContext('2d')

export const request_matchID = () => {
  const message = {
    gamedata: 'request_matchID',
    gameType: gameSettings.gameType,
    withAi: gameSettings.withAi,
    match_id: gameSettings.match_id,
    p1: gameSettings.p1Name,
    p2: gameSettings.p2Name,
    targetScore: gameSettings.targetScore,
  }
  wsOnline.ws_send(message)
}

export const set_training = (data) => {
  if (!data) {
    return
  }
  gameSettings.gameType = 'training'
  gameSettings.p1Name = p1Name = data.uplayerone
  gameSettings.p2Name = p2Name = data.uplayertwo
  gameSettings.targetScore = data.targetScore
  gameSettings.withAi = data.withAi
  gameSettings.match_id = ''
}

export const set_match = (data) => {
  if (!data) {
    return
  }
  gameSettings.gameType = data.gameType
  gameSettings.p1Name = p1Name = data.uplayerone
  gameSettings.p2Name = p2Name = data.uplayertwo
  gameSettings.targetScore = data.targetScore
  gameSettings.match_id = data.match_id
  gameSettings.withAi = false
}

export const sendPaddleMovement = (player_id, direction, pressed) => {
  const message = {
    gamedata: 'paddle_movement',
    player: player_id,
    pName: gameSettings.selfName,
    direction: direction,
    state: pressed,
    match_id: gameSettings.match_id,
  }
  wsOnline.ws_send(message)
}

export const sendPause = () => {
  const message = {
    gamedata: 'pause',
    match_id: gameSettings.match_id,
  }
  wsOnline.ws_send(message)
}

export const recieveMatchID = (data) => {
  gameSettings.match_id = data.content
}

export const updateStartTimer = (data) => {
  startTimer = data.timer
}

export const updatePlayerJoined = (data) => {
  let joined = data['player_joined']
  gameSettings.p2Name = joined
}

export const updateGameState = (data) => {
    if (!data) {
      console.info('No game state data received')
      return
    } else if (!player1 || !player2 || !ballObj) {
      console.info('Game objects not initialized')
      return
    }
    player1.x = data.content.p1x
    player1.y = data.content.p1y
    player1.width = data.content.p1w
    player1.height = data.content.p1h
    p1Score = data.content.p1score
    p1Name = data.content.p1name

    player2.x = data.content.p2x
    player2.y = data.content.p2y
    player2.width = data.content.p2w
    player2.height = data.content.p2h
    p2Score = data.content.p2score
    p2Name = data.content.p2name

    ballObj.x = data.content.ballx
    ballObj.y = data.content.bally
    ballObj.radius = data.content.ballw
    if (data.content.winner) {
      winner = data.content.winner
      endBool = true
    }
  },
  drawScores = () => {
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillStyle = '#222222'
    ctx.font = '700 200px "Inter"'
    if (!startBool || (p1Score == 0 && p2Score == 0)) {
      ctx.fillText('0', canvas.width / 4, canvas.height / 2 - 15)
      ctx.fillText('0', (canvas.width / 4) * 3, canvas.height / 2 - 15)
      ctx.font = '700 50px "Inter"'
      ctx.fillText(p1Name, canvas.width / 4, canvas.height / 2 + 90)
      ctx.fillText(p2Name, (canvas.width / 4) * 3, canvas.height / 2 + 90)
    } else {
      ctx.fillText(p1Score, canvas.width / 4, canvas.height / 2 - 15)
      ctx.fillText(p2Score, (canvas.width / 4) * 3, canvas.height / 2 - 15)
      ctx.font = '700 50px "Inter"'
      ctx.fillText(p1Name, canvas.width / 4, canvas.height / 2 + 90)
      ctx.fillText(p2Name, (canvas.width / 4) * 3, canvas.height / 2 + 90)
    }
    ctx.fillStyle = 'white'
    ctx.fillRect(canvas.width / 2, 0, 1, canvas.height)
  },
  renderLoop = () => {
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    drawScores()
    if (player1) player1.draw(ctx)
    if (player2) player2.draw(ctx)
    if (ballObj) ballObj.draw(ctx)
  },
  game_loop = () => {
    setTimeout(function () {
      if (endBool == true) {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.fillStyle = 'white'
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'
        ctx.font = '700 100px "Inter"'
        ctx.fillText('Game Over', canvas.width / 2, canvas.height / 2 - 50)
        ctx.fillText(
          winner + ' Wins!',
          canvas.width / 2,
          canvas.height / 2 + 50
        )
        // Draw the button
        var textWidth = 200
        var textHeight = 50
        var textX = canvas.width / 2 - textWidth / 2
        var textY = canvas.height / 2 + 200
        p1Score = p2Score = 0
        ctx.fillStyle = 'white'
        ctx.font = '700 24px "Inter"'
        ctx.fillText('Click to exit', canvas.width / 2, textY + textHeight / 2)
        return
      }
      if (startBool == false) {
        if (startTimer > 0) {
          ctx.clearRect(0, 0, canvas.width, canvas.height)
          ctx.fillStyle = 'white'
          ctx.textAlign = 'center'
          ctx.textBaseline = 'middle'
          switch (Math.trunc(startTimer / 30)) {
            case 3:
              var alpha = (startTimer - 90) / 30
              var size = alpha * 200
              ctx.font = '700 ' + size + 'px "Inter"'
              ctx.fillStyle = 'rgba(255, 255, 255 ,' + alpha + ')'
              break
            case 2:
              var alpha = (startTimer - 60) / 30
              var size = alpha * 200
              ctx.font = '700 ' + size + 'px "Inter"'
              ctx.fillStyle = 'rgba(255, 255, 255 ,' + alpha + ')'
              break
            case 1:
              var alpha = (startTimer - 30) / 30
              var size = alpha * 200
              ctx.font = '700 ' + size + 'px "Inter"'
              ctx.fillStyle = 'rgba(255, 255, 255 ,' + alpha + ')'
              break
            case 0:
              var alpha = startTimer / 30
              var size = alpha * 500
              player1.draw(ctx)
              player2.draw(ctx)
              drawScores()
              ctx.beginPath()
              ctx.fillStyle = 'rgba(255, 255, 255 ,' + (1 - alpha) + ')'
              ctx.arc(
                canvas.width / 2,
                canvas.height / 2,
                size,
                0,
                Math.PI * 2,
                false
              )
              ctx.fill()
              ctx.closePath()
              ctx.font = '700 0px "Inter"'
              break
            default:
              ctx.font = '700 50px "Inter"'
              break
          }
          ctx.fillText(
            Math.trunc(startTimer / 30),
            canvas.width / 2,
            canvas.height / 2
          )
        } else {
          startBool = true
          startTimer = -1
        }
      } else renderLoop()
      requestAnimationFrame(game_loop)
    }, 1000 / 60)
  },
  start_game_process = () => {
    player1 = new Paddle(50, canvas.height / 2, 15, 100, 'white')
    player2 = new Paddle(canvas.width - 50, canvas.height / 2, 15, 100, 'white')
    ballObj = new Ball(canvas.width / 2, canvas.height / 2, 10, 'white')

    startBool = false
    startTimer = 120

    {
      // Event listener for key press
      document.addEventListener('keydown', onGameKeyDown, false)

      // Event listener for key release
      document.addEventListener('keyup', onGameKeyUp, false)

      // Event listener for key press
      document.addEventListener('mousedown', onGameKeyDown, false)

      // Event listener for key release
      document.addEventListener('mouseup', onGameKeyUp, false)

      // ! Event listener for touch init press
      document.addEventListener('touchstart', onGameKeyDown, false)

      // ! Event listener for touch release
      document.addEventListener('touchend', onGameKeyUp, false)

      // ? disbale right click
      document.addEventListener('contextmenu', onRightClick, false)

      // ! Even listener for mouse click
      // TODO This event should be added ONLY AFTER GAME IS FINISHED. This would avoid conflicts with other click events up until that point on screen
      document.addEventListener('click', onClickButton, false)

      game_loop()
    }
  }

const onRightClick = (event) => {
  event.preventDefault && event.preventDefault()
  event.stopPropagation && event.stopPropagation()
  event.cancelBubble = true
  event.returnValue = false

  return false
}

const onClickButton = (event) => {
  if (endBool) {
    // Exit the game
    document.body.classList.remove('playing')
    if (!gameSettings.tournament) window.location.hash = '/profile/#/overview'
    else {
      const iWon = winner == gameSettings.selfName
      gameSettings.leave_ack = !iWon
      gameSettings.disablePlay = true
      gameSettings.needDisablePlayNow = true
      if (iWon)
        myWaitingRoomInfoText.innerHTML = `<h4>The tournament ${gameSettings.tournament} still going</h4>
        <h4>Wait for the next round</h4>`
      else myWaitingRoomInfoText.innerHTML = '<h4>You were eliminated</h4>'
      goToWaiting()
    }
  }
}

const onGameKeyDown = (event) => {
  event.preventDefault()

  if (event.key === 'ArrowUp' && !p2up) {
    p2up = true
    sendPaddleMovement(2, 'up', true)
  } else if (event.key === 'ArrowDown' && !p2down) {
    p2down = true
    sendPaddleMovement(2, 'down', true)
  }
  if (event.key === 'w' && !p1up) {
    p1up = true
    sendPaddleMovement(1, 'up', true)
  } else if (event.key === 's' && !p1down) {
    p1down = true
    sendPaddleMovement(1, 'down', true)
  }

  if (event.target.tagName.toLowerCase() == 'button') {
    if (event.target.parentNode.classList.contains('game-ui-buttons-left')) {
      if (event.target.classList.contains('up') && !p1up) {
        p1up = true
        sendPaddleMovement(1, 'up', true)
      } else if (!p1down) {
        p1down = true
        sendPaddleMovement(1, 'down', true)
      }
    } else if (
      event.target.parentNode.classList.contains('game-ui-buttons-right')
    ) {
      if (event.target.classList.contains('up') && !p2up) {
        p2up = true
        sendPaddleMovement(2, 'up', true)
      } else if (!p2down) {
        p2down = true
        sendPaddleMovement(2, 'down', true)
      }
    }
  }
}

const onGameKeyUp = (event) => {
  if (event.key === 'ArrowUp') {
    p2up = false
    sendPaddleMovement(2, 'up', false)
  } else if (event.key === 'ArrowDown') {
    p2down = false
    sendPaddleMovement(2, 'down', false)
  }
  if (event.key === 'w') {
    p1up = false
    sendPaddleMovement(1, 'up', false)
  } else if (event.key === 's') {
    p1down = false
    sendPaddleMovement(1, 'down', false)
  }

  if (event.target.tagName.toLowerCase() == 'button') {
    if (event.target.parentNode.classList.contains('game-ui-buttons-left')) {
      if (event.target.classList.contains('up')) {
        p1up = false
        sendPaddleMovement(1, 'up', false)
      } else {
        p1down = false
        sendPaddleMovement(1, 'down', false)
      }
    }
    if (event.target.parentNode.classList.contains('game-ui-buttons-right')) {
      if (event.target.classList.contains('up')) {
        p2up = false
        sendPaddleMovement(2, 'up', false)
      } else {
        p2down = false
        sendPaddleMovement(2, 'down', false)
      }
    }
  }
}

const onResponsiveButtonMouseUp = (e) => {
  // TODO add logic for buttons here
  // ! needs a way to differentiate between press and release to work better
  // ! also needs to be able to handle multiple buttons pressed at the same time
  if (e.target.parentNode.classList.contains('game-ui-buttons-left')) {
    if (e.target.classList.contains('up')) {
      sendPaddleMovement(1, 'down', false)
      sendPaddleMovement(1, 'up', true)
    } else {
      sendPaddleMovement(1, 'up', false)
      sendPaddleMovement(1, 'down', true)
    }
  }
  if (e.target.parentNode.classList.contains('game-ui-buttons-right')) {
    if (e.target.classList.contains('up')) {
      sendPaddleMovement(2, 'down', false)
      sendPaddleMovement(2, 'up', true)
    } else {
      sendPaddleMovement(2, 'up', false)
      sendPaddleMovement(2, 'down', true)
    }
  }
}

const onResponsiveButtonMouseDown = (e) => {
  e.preventDefault()

  // TODO add logic for buttons here
  // ! needs a way to differentiate between press and release to work better
  // ! also needs to be able to handle multiple buttons pressed at the same time
  if (e.target.parentNode.classList.contains('game-ui-buttons-left')) {
    if (e.target.classList.contains('up')) {
      sendPaddleMovement(1, 'down', false)
      sendPaddleMovement(1, 'up', true)
    } else {
      sendPaddleMovement(1, 'up', false)
      sendPaddleMovement(1, 'down', true)
    }
  }
  if (e.target.parentNode.classList.contains('game-ui-buttons-right')) {
    if (e.target.classList.contains('up')) {
      sendPaddleMovement(2, 'down', false)
      sendPaddleMovement(2, 'up', true)
    } else {
      sendPaddleMovement(2, 'up', false)
      sendPaddleMovement(2, 'down', true)
    }
  }
}

export const playingScreenInit = () => {
  // * ENTRY POINT

  myResponsiveButtons = document.querySelectorAll('#gaming button')

  globalResEvent = new CustomEvent('resize', {
    detail: 'resize',
  }) // ? Create the resize event

  window.addEventListener('resize', resizeGlobalThrottler, false) // ? listen for a resize event on the page

  // ! Here we trigger the resize event ourselves in order to show / hide buttons dependig on viewport / orientation on mobile devices. This is just once, since After this point any changes will be triggered by the event listener set on index.js

  window.dispatchEvent(globalResEvent) // ? Trigger the event

  startBool = false
  endBool = false
  match_id = null
  start_game_process()
}

export const playingScreenDestroy = () => {
  // Event listener for key press
  document.removeEventListener('keydown', onGameKeyDown, false)

  // Event listener for key release
  document.removeEventListener('keyup', onGameKeyUp, false)

  // ! Event listener for touch init press
  document.removeEventListener('touchstart', onGameKeyDown, false)

  // ! Event listener for touch release
  document.removeEventListener('touchend', onGameKeyUp, false)

  // Event listener for key press
  document.removeEventListener('mousedown', onGameKeyDown, false)

  // Event listener for key release
  document.removeEventListener('mouseup', onGameKeyUp, false)

  //Even listener for mouse click
  document.removeEventListener('click', onClickButton, false)

  ctx.clearRect(0, 0, canvas.width, canvas.height)

  window.removeEventListener('resize', resizeGlobalThrottler, false)

  document.removeEventListener('contextmenu', onRightClick, false)

  player1 =
    player2 =
    ballObj =
    startBool =
    endBool =
    startTimer =
    winner =
    p1up =
    p1down =
    p2up =
    p2down =
    myfont =
    myResponsiveButtons =
    globalResizeTimeout =
    globalResEvent =
      null
}
