[
  {
    "inputs": [],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "_tournamentName",
        "type": "string"
      },
      {
        "components": [
          {
            "components": [
              {
                "internalType": "string",
                "name": "name",
                "type": "string"
              },
              {
                "internalType": "uint256",
                "name": "score",
                "type": "uint256"
              }
            ],
            "internalType": "struct PongTournaments.Player",
            "name": "player1",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "string",
                "name": "name",
                "type": "string"
              },
              {
                "internalType": "uint256",
                "name": "score",
                "type": "uint256"
              }
            ],
            "internalType": "struct PongTournaments.Player",
            "name": "player2",
            "type": "tuple"
          }
        ],
        "internalType": "struct PongTournaments.Game[]",
        "name": "_games",
        "type": "tuple[]"
      }
    ],
    "name": "createAndSubmitGames",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "_tournamentName",
        "type": "string"
      }
    ],
    "name": "getGames",
    "outputs": [
      {
        "components": [
          {
            "components": [
              {
                "internalType": "string",
                "name": "name",
                "type": "string"
              },
              {
                "internalType": "uint256",
                "name": "score",
                "type": "uint256"
              }
            ],
            "internalType": "struct PongTournaments.Player",
            "name": "player1",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "string",
                "name": "name",
                "type": "string"
              },
              {
                "internalType": "uint256",
                "name": "score",
                "type": "uint256"
              }
            ],
            "internalType": "struct PongTournaments.Player",
            "name": "player2",
            "type": "tuple"
          }
        ],
        "internalType": "struct PongTournaments.Game[]",
        "name": "",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "name": "tournaments",
    "outputs": [
      {
        "internalType": "string",
        "name": "name",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
]
