export const myVerticalAppView = document.querySelector(
    'section.app div.section-holder div.holder-frame div.holder'
  ),
  myMainNav = document.querySelector('section.app div.section-holder nav'),
  myUsernameMenuText = document.querySelector('nav > li:first-of-type span a'),
  myMainNavButtons = document.querySelectorAll(
    'section.app div.section-holder nav > li'
  ),
  myLoginSection = document.querySelector('section.login'),
  myAppSectionTitleContainer = document.querySelector(
    'section.app div.app-section-header div.title-container h4'
  ),
  myAppSectionSlides = document.querySelectorAll(
    'section.app div.holder-frame div.holder div[class*="slide"]'
  ),
  myNavMobileButton = document.querySelector(
    'section.app div.app-section-header div.mobile-icon-container i'
  ),
  myPreloaderText = document.querySelector(
    'main section.app div.section-holder div.holder-frame div.preloader div.preloader-info span'
  ),
  myWaitingRoomInfoText = document.getElementById('waiting').querySelector('div.waiting-content'),
  myMenuAvatar = document.querySelector('main section.app div.app-section-header div.avatar-container div.avatar-image-container'),
  CONTRACT_ADDRESS = '0x0Df55f04B127eE600FD50A578aC4FFD7Ef220f9E'
