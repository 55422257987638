import { nginx_https_host } from '../../../..'
import Toaster from '../../../../classes/Toaster'
import Fetcher from '../../../../classes/Fetcher'
import {
  setEthers,
  getAccounts,
  storeTournament,
} from '../../../utils/ethers.utils'

let myTournamentList,
  myContractBtns,
  metaHeader,
  metaBtn,
  metaAddress,
  myToaster

const showContractUi = (_account) => {
    metaHeader.dataset.metaLogged = 'logged'

    metaBtn.textContent = 'DISCONNECT WALLET'

    Array.from(myContractBtns).map((_obj) => {
      _obj.classList.remove('hidden')
    })

    metaAddress.textContent =
      '' + _account.substr(0, 6) + '...' + _account.substr(_account.length - 3)
  },
  hideContractUi = () => {
    metaHeader.dataset.metaLogged = ''
    metaBtn.textContent = 'CONNECT WALLET'
    metaAddress.textContent = '...'

    Array.from(myContractBtns).map((_obj) => {
      _obj.classList.add('hidden')
    })
  },
  metaLogIn = async (e) => {
    e.preventDefault()

    const accounts = getAccounts().then((accounts) => {
      if (metaHeader.dataset.metaLogged != 'logged') {
        localStorage.setItem('transcendence-meta-login', accounts[0])
        showContractUi(accounts[0])
      } else {
        localStorage.removeItem('transcendence-meta-login')
        hideContractUi()
      }
    })
  },
  onTournamentBlockChainAction = (e) => {
    const tourn_dataset = e.target.parentNode.parentNode.parentNode.dataset

    if (e.target.textContent == 'store on the blockchain') {
      // TODO fetch endpoint con el id del torneo para obtener scores con el formato de abajo

      const tournamentName = tourn_dataset.tournamentName
      let myFetcher = new Fetcher()
      let url = `${nginx_https_host}/api/scheduling/tournament_${tourn_dataset.tournamentId}/matches/`

      myFetcher.getAuthData(url).then((games) => {
        // TODO see what is returned fron DB. Make sure results are not repeated

        myToaster = new Toaster('Transaction in progress, please wait...')

        storeTournament(tournamentName, games).then((_txHash) => {
          console.log('transaction hash is ', _txHash)

          if (_txHash.length > 1) {
            console.log('saving txhash into de db with string ', _txHash)

            myToaster ? myToaster.closeToaster() : null

            myToaster = new Toaster('Transaction Successful!')

            // TODO store _txHash in the db. This will tell us which tournaments are alreayd beign uploaded to the blockchain as we load the page and and as well the address to which send users to when clicking on "see on the blockchain"
            url = `${nginx_https_host}/api/scheduling/tournament_${tourn_dataset.tournamentId}/published/`
            myFetcher
              .postAuthData(url, { tx_hash: _txHash })
              .then(() => {
                // ? This will do refresh the screen with new data if on hallscreenInit we fecth from db again?
                // TODO make the fecthing of content on a different function so we can call a refresh after the changes are done

                const my_targetDiv = e.target.parentNode.parentNode.parentNode

                my_targetDiv.querySelector(
                  'div.hall-list-row div.tournament-row.right-side span'
                ).textContent = 'see on the blockchain'

                tourn_dataset.tournamentTxhash = _txHash
              })
              .catch((errormsg) => {
                console.error(errormsg)
              })
          } else {
            myToaster
              ? myToaster.setNewText(
                  'There was an error sending the transaction'
                )
              : (myToaster = new Toaster(
                  'There was an error sending the transaction'
                ))
          }
        })
      })
    } else {
      const tournamentTxhash = tourn_dataset.tournamentTxhash
      window.open(
        `https://sepolia.etherscan.io/tx/${tournamentTxhash}`,
        // 'https://sepolia.etherscan.io/address/0x0Df55f04B127eE600FD50A578aC4FFD7Ef220f9E#readContract',
        '_blank' // ? This would need to send user to the page of the hash associated with the transaction. This would need to be stored in the db as transaction is confirmed
      )
    }
  }

export const hallScreenInit = () => {
  let hall = document.querySelector('.hall-list-container')
  let myFetcher = new Fetcher()
  let url = nginx_https_host + '/api/scheduling/tournament/html/'

  myFetcher.getAuthDataHtml(url).then((res) => {
    hall.innerHTML = res

    // ? Setup nodes

    myTournamentList = document.querySelectorAll(
      'div#hall div.hall-list-container div.hall-list-row-container'
    )

    myContractBtns = document.querySelectorAll(
      'div.hall-list-row div.tournament-row.right-side span'
    )

    metaHeader = document.querySelector('div#hall div.hall-metamask')

    metaAddress = metaHeader.querySelector(
      'div.metamask-ui div.metamask-address'
    )

    metaBtn = document.querySelector(
      'div#hall div.hall-metamask div.metamask-ui button'
    )

    // TODO get which tournaments are already uploaded mathing with the data from the blockchain to display either "STORE ON THE BLOCKCHAIN" or "VIEW  ON THE BLOCKCHAIN"

    myContractBtns.forEach((el, i) => {
      if (
        el.parentNode.parentNode.parentNode.dataset.tournamentTxhash == 'None'
      ) {
        el.textContent = 'store on the blockchain'
      } else {
        el.textContent = 'see on the blockchain'
      }
    })

    // ? Check for metamask

    if (window.ethereum) {
      setEthers() // ? Set ethers vars only if metamask is available

      if (
        localStorage.getItem('transcendence-meta-login') &&
        window.ethereum.isConnected()
      ) {
        showContractUi(localStorage.getItem('transcendence-meta-login'))
      } else {
        hideContractUi()
      }

      metaBtn.addEventListener('click', metaLogIn, false)
    } else {
      myToaster = new Toaster(
        '<p style="padding:0 0.5rem 0 0">Please install metamask and connect to sepolia test network to be able to interact with the blockchain</p>'
      )
    }

    // ? Listen for clicks on the button for each tournament
    myContractBtns.forEach((el, i) => {
      el.addEventListener('click', onTournamentBlockChainAction, false)
    })
  })
}

export const hallScreenDestroy = () => {
  myContractBtns.forEach((el, i) => {
    el.removeEventListener('click', onTournamentBlockChainAction, false)
  })

  myTournamentList =
    myContractBtns =
    metaHeader =
    metaBtn =
    metaAddress =
    myToaster =
      null
}
