import Fetcher from '../../../classes/Fetcher'
import Toaster from '../../../classes/Toaster'
import { wsOnline } from '../../../index'

const showToaster = msg =>
{
  let myToaster = new Toaster(msg);
}

function isInList(value)
{
  return this.includes(value);
}

const createDictReferences = (references, dictSample) =>
{
  let res = {};
  
  for (const [key, value] of Object.entries(dictSample))
  {
    if (references.includes(key))
      res[key] = value;
  }
  return res;
}

export const setAnchorRegister42Link = register42Anchor =>
{
  let myFetcher = new Fetcher();
  let url = window.location.origin + "/api/login42/";
  
  myFetcher.getData(url).then(res =>
  {
	let link = "";

    if (Object.keys(res).includes("link_42"))
      link = res["link_42"];
    register42Anchor.href = link;
  });
}

const registUser42 = code =>
{
  let myFetcher = new Fetcher();
  let url = window.location.origin + "/api/login42/";

  myFetcher.postData(url, {'code': code}).then(res =>
  {
    let obkeys = Object.keys(res);
  	let neededFields = [
	  "access", 
	  "refresh", 
	  "username", 
	  "userid", 
	  "avatar"
	];

    if (neededFields.every(isInList, obkeys))
	{
      localStorage.setItem(
        'transcendence',
        JSON.stringify(createDictReferences(neededFields, res))
	  );
      document.body.classList.remove('not-logged');
      document.body.classList.add('logged');
      document.querySelector(
        'section.app nav >li:first-of-type span a'
      ).textContent = res['username'].toString(); // ? Set username on menu before shoing app section
      if (wsOnline) wsOnline.ws_init(res['username']);
      else console.error("wsOnline is", wsOnline)
	  window.location.hash = '/profile/#/overview';
    }
	else
	{
      if (wsOnline) wsOnline.ws_close();
      localStorage.removeItem('transcendence');
	  showToaster(res.errormsg.toString());
	  window.location.hash = '/splash';
    }
  });
}

export const handleQueryParameters = (query_params) =>
{
  if (query_params.has('code'))
    registUser42(query_params.get('code'));
  else if (query_params.has('error'))
  {
	showToaster(query_params.get('error_description'));
	window.location.hash = '/splash';
  }
}
