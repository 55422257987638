import gameSettings from '../../../../config/game'
import { wsOnline } from '../../../index'
import { playingScreenDestroy } from './playing'
import { myWaitingRoomInfoText } from '../../../globals/dom'

let myUi, myPlayUi, userName

const onUiClick = (e) => {
  e.preventDefault()
  gameSettings.leave_ack = false
  uiLeaveOrAcknowledge(false)
  let message = {}
  if (e.target.classList.contains('waiting-btn')) {
    if (gameSettings.tournament == null || gameSettings.tournament == '') {
      // isn't tournament
      if (gameSettings.gameType && gameSettings.match_id) {
        // is game
        message = {
          gamedata: 'request_leave',
          gameType: gameSettings.gameType,
          match_id: gameSettings.match_id,
        }
        wsOnline.ws_send(message)
        resetGameInGameSettings()
      }
    } else {
      // is tournament
      message = {
        tourndata: 'request_leave',
        tournament_id: gameSettings.tournament,
        match_id: gameSettings.match_id,
        tournament_id: gameSettings.round,
        username: gameSettings.selfName,
      }
      wsOnline.ws_send(message)
      resetGameInGameSettings()
      resetTournInGameSettings()
    }
    waitingScreenDestroy()
    document.body.classList.remove('waiting')
    document.location.hash = '/'
  }
}

const onPlayUiClick = (e) => {
  announceMatch(false) // ? This is the way of disbaling the play now button
  initMatch()
}

export const request_matchID = () => {
  let userName
  if (gameSettings.gameType === 'training') userName = gameSettings.selfName
  else userName = 'None'
  const message = {
    gamedata: 'request_matchID',
    gameType: gameSettings.gameType,
    withAi: gameSettings.withAi,
    pName: userName,
    match_id: gameSettings.match_id,
    p1: gameSettings.p1Name,
    p2: gameSettings.p2Name,
    targetScore: gameSettings.targetScore,
    tourn: gameSettings.tournament,
  }
  wsOnline.ws_send(message)
}

function waitForMatchID() {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (gameSettings.match_id !== '') {
        clearInterval(interval)
        resolve()
      }
    }, 1000)
  })
}

export const initMatch = () => {
  waitForMatchID().then(() => {
    start_match(
      gameSettings.p1Name,
      gameSettings.p2Name,
      gameSettings.targetScore,
      gameSettings.gameType
    )
  })
}

export const announceInitMatch = () => {
  announceMatch(true) // ? announce match with PLAY NOW button enabled
}

export const createdTournMatch = (data) => {
  if (
    'tournament_id' in data &&
    'match_id' in data &&
    'targetScore' in data &&
    'players' in data &&
    'round' in data
  ) {
    if (
      data['players'].includes(gameSettings.selfName) &&
      gameSettings.tournament == data['tournament_id']
    ) {
      gameSettings.tourn_winner = null
      gameSettings.tourn_won = null
      resetGameInGameSettings()
      gameSettings.match_id = data['match_id']
      gameSettings.p1Name = data['players'][0]
      gameSettings.p2Name = data['players'][1]
      gameSettings.gameType = 'private'
      gameSettings.targetScore = data['targetScore']
      gameSettings.round = data['round']
      gameSettings.needDisablePlayNow = false
      announceInitMatch()
      request_matchID(gameSettings.tournament)
    }
  }
}

export const initNewRound = (data) => {
  if (data.round_winners.includes(gameSettings.selfName)) {
    // ? if the user passed the round
    gameSettings.tourn_winner = null
    gameSettings.tourn_won = null
    gameSettings.round = data.round_finished
    resetGameInGameSettings()
    gameSettings.leave_ack = false
    gameSettings.disablePlay = true
    gameSettings.needDisablePlayNow = true
    myWaitingRoomInfoText.innerHTML = `<h4>You passed the round ${gameSettings.round}</h4>`
    goToWaiting()
  } else if (data.tourn_losers.includes(gameSettings.selfName)) {
    // ? The user lose
    resetGameInGameSettings()
    resetTournInGameSettings()
    goToOverview()
  }
}

export const afterRivalGiveupNewRound = (data) => {
  gameSettings.round = data.round
  gameSettings.match_id = null
  gameSettings.gameType = null
  waitingScreenDestroy()
  waitingScreenInit()
  gameSettings.leave_ack = false
  gameSettings.disablePlay = true
  gameSettings.needDisablePlayNow = true
  myWaitingRoomInfoText.innerHTML = `<h4>You passed the round ${gameSettings.round} because your rival gave up</h4>`
  waitingScreenDestroy()
  waitingScreenInit()
  goToWaiting()
}

export const finishTourn = (data) => {
  // ? tournament is done
  resetTournInGameSettings()
  gameSettings.tourn_winner = data.tourn_winner
  gameSettings.tourn_won = data.tournament_id
  gameSettings.needDisablePlayNow = true
  gameSettings.disablePlay = true
  enablePlayUi(false)
  gameSettings.leave_ack = true
  uiLeaveOrAcknowledge(true)
  myWaitingRoomInfoText.innerHTML = `<h4>The winner of the tournament <span>${data.tournament_id}</span> was <span>${data.tourn_winner}!!</span></h4>`
  goToWaiting()
}

export const joinedTourn = (data) => {
  // ? Joining a tournamentdata_info
  resetGameInGameSettings()
  resetTournInGameSettings()
  gameSettings.tournament = data.tournament_id
  gameSettings.needDisablePlayNow = true
  myWaitingRoomInfoText.innerHTML = `<h4>Joined the tournament <span>${data.tournament_id}</span></h4>`
  goToWaiting()
}

export const start_match = (
  _name1 = 'Player 1',
  _name2 = 'player 2',
  _score = 2,
  _game_type = 'training'
) => {
  const message = {
    gamedata: 'start_match',
    gameType: _game_type,
    p1: _name1,
    p2: _name2 == 'None' ? null : _name2,
    targetScore: _score,
    match_id: gameSettings.match_id,
  }
  wsOnline.ws_send(message)
}

export const request_tournament = () => {
  const userName = gameSettings.selfName
  const message = {
    tourndata: 'request_tournament',
    pName: userName,
    tournament_id: gameSettings.tournament,
  }
  wsOnline.ws_send(message)
}

export const request_round = () => {
  const userName = gameSettings.selfName
  const message = {
    tourndata: 'request_round',
    pName: userName,
    tournament_id: gameSettings.tournament,
  }
  wsOnline.ws_send(message)
}

export const playerGiveupTourn = (data) => {
  const dataKeys = Object.keys(data)
  const expectedKeys = ['tournament_id', 'username', 'match', 'round', 'rounds']
  let interSet = []
  expectedKeys.forEach((element) => {
    if (dataKeys.includes(element)) interSet.push(element)
  })
  if (interSet.length >= expectedKeys.length) {
    if (
      gameSettings.match_id == data['match'] &&
      gameSettings.selfName != data['username'] &&
      (gameSettings.p1Name == data['username'] ||
        gameSettings.p2Name == data['username'])
    ) {
      if (data.round == data.rounds) {
        waitingScreenDestroy()
        waitingScreenInit()
        finishTourn({
          tourn_winner: gameSettings.selfName,
          tournament_id: data['tournament_id'],
        })
      } else afterRivalGiveupNewRound(data)
    }
  } else console.log('on playerGiveupTourn some key lacks on data', dataKeys)
}

export const goToPlaying = () => {
  document.body.classList.remove('waiting')
  document.body.classList.add('playing')
  document.location.hash = ''
}

export const goToWaiting = () => {
  document.body.classList.remove('playing')
  document.body.classList.add('waiting')
  document.location.hash = '/waiting'
}

export const goToOverview = () => {
  document.body.classList.remove('playing')
  document.body.classList.remove('waiting')
  document.location.hash = ''
}

export const resetGameSettings = () => {
  gameSettings.gameType = ''
  gameSettings.p1Name = null
  gameSettings.p2Name = null
  gameSettings.targetScore = null
  gameSettings.match_id = ''
  gameSettings.withAi = false
  gameSettings.tournament = null
  gameSettings.round = 0
  gameSettings.tourn_winner = null
  gameSettings.tourn_won = null
}

export const resetGameInGameSettings = () => {
  gameSettings.gameType = ''
  gameSettings.p1Name = null
  gameSettings.p2Name = null
  gameSettings.targetScore = null
  gameSettings.match_id = ''
  gameSettings.withAi = false
}

export const resetTournInGameSettings = () => {
  gameSettings.tournament = null
  gameSettings.round = 0
  gameSettings.tourn_winner = null
  gameSettings.tourn_won = null
  gameSettings.tourn_winner = null
  gameSettings.tourn_won = null
}

export const updateTournAbort = (data) => {
  resetTournInGameSettings()
  if (document.body.classList.contains('waiting')) {
    document.body.classList.remove('waiting')
    waitingScreenDestroy()
  }
  if (document.body.classList.contains('playing')) {
    document.body.classList.remove('playing')
    playingScreenDestroy()
  }
  document.location.hash = ''
}

export const updateTournCont = (data) => {
  if (
    data['tournament'] != gameSettings.tournament ||
    (data['username'] != gameSettings.p1Name &&
      data['username'] != gameSettings.p2Name)
  ) {
    return
  }
  if (document.body.classList.contains('playing')) {
    document.body.classList.remove('playing')
    playingScreenDestroy()
  }

  if (data['round'] == data['rounds']) {
    finishTourn({
      tourn_winner: gameSettings.selfName,
      tournament_id: data['tournament_id'],
    })
    show_tournament_winner()
  }

  return
}

export const updateGameAbort = (data) => {
  resetGameInGameSettings()
  goToOverview()
}

export const updateRivalAbort = (data) => {
  if (gameSettings.match_id && gameSettings.match_id != '') {
    if (document.body.classList.contains('playing')) {
      document.body.classList.remove('playing')
      playingScreenDestroy()
    }
    myWaitingRoomInfoText.innerHTML = `<h4>Your opponent in game <span>${gameSettings.match_id}</span> gave up, you win!!</span></h4>`
    gameSettings.disablePlay = true
    gameSettings.needDisablePlayNow = true
    gameSettings.leave_ack = true
    resetGameInGameSettings()
    goToWaiting()
  }
  resetGameInGameSettings()
}

const show_tournament_winner = () => {
  resetTournInGameSettings()
  gameSettings.leave_ack = true
}

const announceMatch = (enable = true) => {
  // TODO change the message for initial round
  let myHTMLOutput = ``

  if (gameSettings.tournament) {
    // * tournaments
    const curRound = gameSettings.round - 1
    if (curRound > 0) {
      myHTMLOutput += `<h4>You passed the round ${curRound}</h4>`
    }

    myHTMLOutput += `<h4>You'll play the next round of the tournament <span>${gameSettings.tournament}</span></h4>`
    myHTMLOutput += `<h6>Round ${gameSettings.round}</h6>`

    myHTMLOutput += `<h4 class="mt-3"><span>${gameSettings.p1Name}</span> vs <span>${gameSettings.p2Name}</span>!</h4>`
  } else {
    // * single player
    // ? create_private
    // ? public
    // ? training

    if (gameSettings.gameType == 'public') {
      // ? public
      if (enable) myHTMLOutput += `<h4>Whenever you are ready!</h4>`
      else
        myHTMLOutput += `<h4>Waiting for the other player</h4> <h6>(This can take a while...)</h6>`
    } else if (gameSettings.gameType == 'training') {
      // ? training
      myHTMLOutput += `<h4>Whenever you are ready!</h4>`
    } else {
      // ? private
      if (
        gameSettings.p2Name == 'None' ||
        gameSettings.p2Name == null ||
        gameSettings.p2Name.length < 1
      ) {
        let myStringId = gameSettings.match_id

        myHTMLOutput += `<h4>The id for your game is: <span>${myStringId.substring(
          myStringId.indexOf('-') + 1,
          myStringId.length
        )}</span></h4>
      <h6>(Do not forget to share the code with your partner!)</h6>
      `
      // if (!enabled) myHTMLOutput += `<h4>Waiting for the other player</h4> <h6>(This can take a while...)</h6>`


      } else {
        if (enable) myHTMLOutput += `<h4>Whenever you are ready!</h4>`
        else
          myHTMLOutput += `<h4>Waiting for the other player</h4> <h6>(This can take a while...)</h6>`
      }
    }
  }

  myWaitingRoomInfoText.innerHTML = myHTMLOutput

  enablePlayUi(enable)
}

export const waitingScreenInit = () => {
  myUi = document.querySelector('#waiting div.waiting-ui')
  myPlayUi = document.querySelector('#waiting div.waiting-play-ui')

  myUi.addEventListener('click', onUiClick, false)
  myPlayUi.addEventListener('click', onPlayUiClick, false)

  gameSettings.disablePlay = gameSettings.needDisablePlayNow
  gameSettings.needDisablePlayNow = false
  enablePlayUi(!gameSettings.disablePlay)
  uiLeaveOrAcknowledge(gameSettings.leave_ack)
  gameSettings.leave_ack = false

  if (!gameSettings.tourn_winner) {
    if (gameSettings.tournament == null || gameSettings.tournament == '') {
      if (gameSettings.match_id) announceInitMatch()
    } else {
      if (gameSettings.round == 0) request_tournament()
      else request_round()
    }
  } else show_tournament_winner()
}

export const waitingScreenDestroy = () => {
  if (myUi) {
    try {
      myUi.removeEventListener('click', onUiClick, false)
    } catch (err) {
      console.log(err)
    }
  }
  if (myPlayUi) {
    try {
      myPlayUi.removeEventListener('click', onPlayUiClick, false)
    } catch (err) {
      console.log(err)
    }
  }
  myUi = null
  myPlayUi = null
  document.body.classList.remove('waiting')
}

export const enablePlayUi = (enable = true) => {
  if (myPlayUi) myPlayUi.style.display = enable ? 'block' : 'none'
}

export const uiLeaveOrAcknowledge = (loa = false) => {
  if (myUi) {
    const leaveBut = myUi.firstElementChild
    if (leaveBut) {
      if (loa)
        leaveBut.innerHTML = '<i class="bi bi-stop-circle-fill"></i>&nbsp;Ok'
      else
        leaveBut.innerHTML =
          '<i class="bi bi-stop-circle-fill"></i>&nbsp;Leave Game'
    }
  }
}
