import { nginx_https_host } from '../../../..'
import Fetcher from '../../../../classes/Fetcher'

let myOverview,
  myTotalGames,
  myGamesPie,
  myGamesAnimBar,
  myGamesLengendWonText,
  myGamesLengendLostText,
  myTotalPoints,
  myPointsPie,
  myPointsAnimBar,
  myPointsLegendFavorText,
  myPointsLegendAgainstText,
  myTournamentsRounds,
  myTournamentsRoundsPie,
  myTournamentsRoundsAnimBar,
  myTournamentsRoundsWonText,
  myTournamentsRoundsLostText,
  myTournamentsPlayed,
  myTournamentsPlayedPie,
  myTournamentsPlayedAnimBar,
  myTournamentsPlayedLegendWonText,
  myTournamentsPlayedLegendLostText,
  myFetcher,
  url

const incrementPercent = (start, end, duration, node) => {
  if (!node || node == null) {
    // console.log('executing safe for wheels')
    setTimeout(() => {
      overviewScreenDestroy()
      overviewScreenInit()
    }, 100)
    return
  }

  let startTimestamp = null
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp
    const progress = Math.min((timestamp - startTimestamp) / duration, 1)
    node.dataset.percent =
      Math.floor(progress * (end - start) + start)
        .toFixed(1)
        .toString() + '%'

    if (progress < 1) {
      window.requestAnimationFrame(step)
    }
  }
  window.requestAnimationFrame(step)
}

export const overviewScreenInit = () => {
  //? Set dom elements

  myOverview = document.getElementById('overview')

  if (!myOverview || myOverview == null) {
    overviewScreenDestroy()
    overviewScreenInit()
    return
  }

  // ? Store references for the games graphic
  myTotalGames = myOverview.querySelector(
    'div.overview-container div.overview-graph-container div.overview-graph-wheel.games div.overview-total-header span'
  )
  myGamesPie = myOverview.querySelector(
    'div.overview-container div.overview-graph-container div.overview-graph-wheel.games div.overview-wheel-container'
  )

  myGamesAnimBar = myGamesPie.querySelector('circle.bar-games')
  myGamesAnimBar.style.strokeDashoffset = 615.752 // ? Reset the style for the animation

  myGamesLengendWonText = myOverview.querySelector(
    'div.overview-container div.overview-graph-container div.overview-graph-wheel.games div.overview-legend div.overview-legend-won div.overview-legend-text'
  )
  myGamesLengendLostText = myOverview.querySelector(
    'div.overview-container div.overview-graph-container div.overview-graph-wheel.games div.overview-legend div.overview-legend-lost div.overview-legend-text'
  )

  // ? Store references for the points graphic

  myTotalPoints = myOverview.querySelector(
    'div.overview-container div.overview-graph-container div.overview-graph-wheel.points div.overview-total-header span'
  )

  myPointsPie = myOverview.querySelector(
    'div.overview-container div.overview-graph-container div.overview-graph-wheel.points div.overview-wheel-container'
  )

  myPointsAnimBar = myPointsPie.querySelector('circle.bar-points')

  myPointsAnimBar.style.strokeDashoffset = 615.752 // ? Reset the style for the animation

  myPointsLegendFavorText = myOverview.querySelector(
    'div.overview-container div.overview-graph-wheel.points div.overview-legend div.overview-legend-favor div.overview-legend-text'
  )
  myPointsLegendAgainstText = myOverview.querySelector(
    'div.overview-container div.overview-graph-wheel.points div.overview-legend div.overview-legend-against div.overview-legend-text'
  )

  // ? Store bars for tournamets played

  myTournamentsPlayed = myOverview.querySelector(
    'div.overview-container div.overview-graph-container div.overview-graph-wheel.tournaments-played div.overview-total-header span'
  )

  myTournamentsPlayedPie = myOverview.querySelector(
    'div.overview-container div.overview-graph-container div.overview-graph-wheel.tournaments-played div.overview-wheel-container'
  )

  myTournamentsPlayedAnimBar = myTournamentsPlayedPie.querySelector(
    'circle.bar-tournaments-played'
  )

  myTournamentsPlayedAnimBar.style.strokeDashoffset = 615.752 // ? Reset the style for the animation

  myTournamentsPlayedLegendWonText = myOverview.querySelector(
    'div.overview-container div.overview-graph-wheel.tournaments-played div.overview-legend div.overview-legend-won div.overview-legend-text'
  )
  myTournamentsPlayedLegendLostText = myOverview.querySelector(
    'div.overview-container div.overview-graph-wheel.tournaments-played div.overview-legend div.overview-legend-lost div.overview-legend-text'
  )

  // ? Store bars for tournament rounds

  myTournamentsRounds = myOverview.querySelector(
    'div.overview-container div.overview-graph-container div.overview-graph-wheel.tournaments-rounds div.overview-total-header span'
  )

  myTournamentsRoundsPie = myOverview.querySelector(
    'div.overview-container div.overview-graph-container div.overview-graph-wheel.tournaments-rounds div.overview-wheel-container'
  )

  myTournamentsRoundsAnimBar = myTournamentsRoundsPie.querySelector(
    'circle.bar-tournaments-rounds'
  )

  myTournamentsRoundsAnimBar.style.strokeDashoffset = 615.752 // ? Reser the style for the animation

  myTournamentsRoundsWonText = myOverview.querySelector(
    'div.overview-container div.overview-graph-wheel.tournaments-rounds div.overview-legend div.overview-legend-won div.overview-legend-text'
  )

  myTournamentsRoundsLostText = myOverview.querySelector(
    'div.overview-container div.overview-graph-wheel.tournaments-rounds div.overview-legend div.overview-legend-lost div.overview-legend-text'
  )

  myFetcher = new Fetcher()
  url = nginx_https_host + '/api/scheduling/match/overview'

  myFetcher.getAuthData(url).then((res) => {
    // * Games logic

    myOverview
      .querySelectorAll('div.overview-wheel-container')
      .forEach((el, i) => {
        el.dataset.percent = ''
      })

    // ? Calculate Games Percent based on games won and degrees based on % of games won

    const myWonPercent = (res.won * 100) / res.total,
      r = myGamesAnimBar.getAttribute('r'),
      c = Math.PI * r * 2,
      pct = ((100 - Math.floor(myWonPercent)) / 100) * c // ? Calculate the precentage of the svg dasharray length

    // myTotalGames.textContent = res.total
    // myGamesPie.dataset.percent = !isNaN(myWonPercent)
    //   ? myWonPercent.toFixed(1) + '%'
    //   : '...'

    myTotalGames.textContent = res.total
    myGamesPie.dataset.percent = !isNaN(myWonPercent) ? '0.0%' : '...'

    myGamesLengendWonText.textContent = 'Wins ' + (1*res.won).toString()
    myGamesLengendLostText.textContent = 'Loses ' + (1*res.lost).toString()

    // * Points logic

    const myTotalPointsPlayed = res.against + res.in_favor,
      myPointsPercent = (res.in_favor * 100) / myTotalPointsPlayed,
      r2 = myPointsAnimBar.getAttribute('r'),
      c2 = Math.PI * r2 * 2,
      pct2 = ((100 - Math.floor(myPointsPercent)) / 100) * c2 // ? Calculate the precentage of the svg dasharray length

    // myTotalPoints.textContent = myTotalPointsPlayed
    // myPointsPie.dataset.percent = !isNaN(myPointsPercent)
    //   ? myPointsPercent.toFixed(1) + '%'
    //   : '...'

    myTotalPoints.textContent = myTotalPointsPlayed
    myPointsPie.dataset.percent = !isNaN(myPointsPercent) ? '0.0%' : '...'

    myPointsLegendFavorText.textContent = 'In favor ' + (1*res.in_favor).toString()
    myPointsLegendAgainstText.textContent = 'Against ' + (1*res.against).toString()

    // * Tournments played logic

    const myTotalTournamentsPlayed = res.played_tourn,
      myTournamentWonPercent = (res.won_tourn * 100) / myTotalTournamentsPlayed,
      r3 = myTournamentsPlayedAnimBar.getAttribute('r'),
      c3 = Math.PI * r3 * 2,
      pct3 = ((100 - Math.floor(myTournamentWonPercent)) / 100) * c3 // ? Calculate the precentage of the svg dasharray length

    // myTournamentsPlayed.textContent = myTotalTournamentsPlayed
    // myTournamentsPlayedPie.dataset.percent = !isNaN(myTournamentWonPercent)
    //   ? myTournamentWonPercent.toFixed(1) + '%'
    //   : '...'

    myTournamentsPlayed.textContent = myTotalTournamentsPlayed
    myTournamentsPlayedPie.dataset.percent = !isNaN(myTournamentWonPercent)
      ? '0.0%'
      : '...'

    myTournamentsPlayedLegendWonText.textContent = 'Winner ' + (1*res.won_tourn).toString()
    myTournamentsPlayedLegendLostText.textContent =
      'Kicked out ' + (1*res.played_tourn - 1*res.won_tourn).toString()

    // * Tournments rounds logic


    const myTotalTournamentsRounds = res.lost_rounds + res.won_rounds + res.missed_rounds,
      myTournamentsRoundsWonPercent =
        (res.won_rounds * 100) / myTotalTournamentsRounds,
      r4 = myTournamentsRoundsAnimBar.getAttribute('r'),
      c4 = Math.PI * r4 * 2,
      pct4 = ((100 - Math.floor(myTournamentsRoundsWonPercent)) / 100) * c4 // ? Calculate the percentage of the svg dasharray length

    // myTournamentsRounds.textContent = myTotalTournamentsRounds
    // myTournamentsRoundsPie.dataset.percent = !isNaN(
    //   myTournamentsRoundsWonPercent
    // )
    //   ? myTournamentsRoundsWonPercent.toFixed(1) + '%'
    //   : '...'

    myTournamentsRounds.textContent = myTotalTournamentsRounds
    myTournamentsRoundsPie.dataset.percent = !isNaN(
      myTournamentsRoundsWonPercent
    )
      ? '0.0%'
      : '...'

    myTournamentsRoundsWonText.textContent = 'Passed ' + (1*res.won_rounds).toString()
    myTournamentsRoundsLostText.textContent = 'Eliminated ' + (1*res.lost_rounds + 1*res.missed_rounds).toString()

    setTimeout(() => {
      if (myGamesAnimBar) myGamesAnimBar.style.strokeDashoffset = pct
        if (myPointsAnimBar) myPointsAnimBar.style.strokeDashoffset = pct2
        if (myTournamentsPlayedAnimBar)
          myTournamentsPlayedAnimBar.style.strokeDashoffset = pct3
        if (myTournamentsRoundsAnimBar)
          myTournamentsRoundsAnimBar.style.strokeDashoffset = pct4
      
      !isNaN(myWonPercent)
        ? incrementPercent(0, myWonPercent, 1000, myGamesPie)
        : null

      !isNaN(myPointsPercent)
        ? incrementPercent(0, myPointsPercent, 1000, myPointsPie)
        : null

      !isNaN(myTournamentWonPercent)
        ? incrementPercent(
            0,
            myTournamentWonPercent,
            1000,
            myTournamentsPlayedPie
          )
        : null

      !isNaN(myTournamentsRoundsWonPercent)
        ? incrementPercent(
            0,
            myTournamentsRoundsWonPercent,
            1000,
            myTournamentsRoundsPie
          )
        : null

      
    }, 1100)
  })
}

export const overviewScreenDestroy = () => {
  myOverview =
    myTotalGames =
    myGamesPie =
    myGamesAnimBar =
    myGamesLengendWonText =
    myGamesLengendLostText =
    myTotalPoints =
    myPointsPie =
    myPointsAnimBar =
    myPointsLegendFavorText =
    myPointsLegendAgainstText =
    myTournamentsPlayed =
    myTournamentsPlayedPie =
    myTournamentsPlayedAnimBar =
    myTournamentsPlayedLegendWonText =
    myTournamentsPlayedLegendLostText =
    myTournamentsRounds =
    myTournamentsRoundsPie =
    myTournamentsRoundsAnimBar =
    myTournamentsRoundsWonText =
    myTournamentsRoundsLostText =
    myFetcher =
    url =
      null
}
