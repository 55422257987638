/**
 * @params _text => The text to show in the Toaster
 * @params _delay => The delay in miliseconds to set automatically close the Toaster. If not passed as param we include the closer button so user decides when to destroy the Toaster
 */

export default class Toaster {
  constructor(_text, _delay) {
    this.text = _text
    this.delay = _delay

    this.container = document.getElementById('toaster-container')
    this.appendToContainer(this.text)
  }

  appendToContainer(_text) {
    this.container.innerHTML = `
    <div class="toaster-widget d-flex justify-content-between align-items-start">
      <div class="toaster-msg">${_text}</div>
      ${
        this.delay == undefined
          ? `
            <div class="toaster-icon">
              <i class="bi bi-x-lg"></i>
            </div>
            `
          : `<div class="toaster-icon d-none">
              <i class="bi bi-x-lg"></i>
            </div>
            `
      }           
    </div>` // ? if delay is set just make the icon not visible

    setTimeout(() => {
      document
        .querySelector('div#toaster-container div.toaster-widget')
        .classList.add('show')
    }, 10) // ? Make the Toaster show, this need to have some delay if not it just appear on the page without transition

    document
      .querySelector('div#toaster-container div.toaster-icon')
      .addEventListener('click', this.onToasterClick, false) // ? Listen for clicks on the closerIcon

    if (this.delay !== undefined) {
      setTimeout(() => {
        this.closeToaster()
      }, this.delay)
    } // ? If we have a delay, close the widget after that by trigerring the click event on the hidden button
  }

  closeToaster() {
    if (this.container.children.length > 0)
      document.querySelector('div#toaster-container div.toaster-icon').click()
  }

  onToasterClick(e) {
    e.currentTarget.removeEventListener('click', this.onToasterClick, false) // ? Remove the event

    let myContainer = document.getElementById('toaster-container')
    myContainer.querySelector('div.toaster-widget').classList.remove('show')

    Toaster.prototype.destroyToast() // ? Destroy the toast
  }

  setNewText(_newText) {
    this.appendToContainer(_newText)
  }

  destroyToast() {
    let myContainer = document.getElementById('toaster-container')
    myContainer.querySelector('div.toaster-widget').classList.remove('show')

    setTimeout(() => {
      while (myContainer.lastChild)
        myContainer.removeChild(myContainer.lastChild) // ? remove any childs
      this.text = this.container = myContainer = null // ? clear vars
    }, 1010)
  }
}
