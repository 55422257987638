import {
  myMainNav,
  myMainNavButtons,
  myNavMobileButton,
} from '../../globals/dom'
import { getActiveMainMenuSubMenu } from '../utils/utils'

export const setActiveMainMenuLink = (_hash) => {
  Array.from(myMainNavButtons).forEach((el, i) => {
    el.dataset.deeplinking == _hash
      ? el.classList.add('active')
      : el.classList.remove('active')
  })
}

export const setActiveSubMenuLink = (_hash) => {
  const myActiveSubMenu = getActiveMainMenuSubMenu()

  Array.from(myActiveSubMenu).forEach((el, i) => {
    el.dataset.deeplinking == _hash
      ? el.classList.add('current')
      : el.classList.remove('current')
  })
}

export const showMobileMenu = () => {
  myMainNav.classList.add('showing-mobile')
  myNavMobileButton.className = 'bi bi-dash-lg'
}

export const hideMobileMenu = () => {
  myMainNav.classList.remove('showing-mobile')
  myNavMobileButton.className = 'bi bi-plus-lg'
}
