import {
  myVerticalAppView,
  myAppSectionTitleContainer,
  myLoginSection,
  myAppSectionSlides,
} from '../../globals/dom'
import { revealStringByChar } from '../utils/utils'
import {
  setActiveMainMenuLink,
  setActiveSubMenuLink,
  hideMobileMenu,
} from '../interactive/interactive_nav'

// * Import modules

// ? section login

import {
  splashScreenInit,
  splashScreenDestroy,
} from '../sections/loginsection/splash'

import {
  signinScreenInit,
  signinScreenDestroy,
} from '../sections/loginsection/singin'

import {
  signupScreenInit,
  signupScreenDestroy,
} from '../sections/loginsection/signup'

// ? section app - profile

import {
  overviewScreenInit,
  overviewScreenDestroy,
} from '../sections/appsection/profile/overview'

import {
  historyScreenInit,
  historyScreenDestroy,
} from '../sections/appsection/profile/history'

import {
  infoeditScreenInit,
  infoeditScreenDestroy,
} from '../sections/appsection/profile/infoedit'

// ? section app - single-game

import {
  trainingScreenInit,
  trainingScreenDestroy,
} from '../sections/appsection/single-game/training'

import {
  publicScreenInit,
  publicScreenDestroy,
} from '../sections/appsection/single-game/public'

import {
  privateScreenInit,
  privateScreenDestroy,
} from '../sections/appsection/single-game/private'

// ? section app - tournament

import {
  createScreenInit,
  createScreenDestroy,
} from '../sections/appsection/tournament/create'

import {
  joinScreenInit,
  joinScreenDestroy,
} from '../sections/appsection/tournament/join'

import {
  hallScreenInit,
  hallScreenDestroy,
} from '../sections/appsection/tournament/hall'

// ? section app - friends

import {
  listScreenInit,
  listScreenDestroy,
} from '../sections/appsection/friends/list'

import {
  addScreenInit,
  addScreenDestroy,
} from '../sections/appsection/friends/add'

// ? section app - about

import {
  aboutgameScreenInit,
  aboutgameScreenDestroy,
} from '../sections/appsection/about/aboutgame'

import {
  aboutteamScreenInit,
  aboutteamScreenDestroy,
} from '../sections/appsection/about/aboutteam'

// ? section app - logout

import {
  confirmScreenInit,
  confirmScreenDestroy,
} from '../sections/appsection/logout/confirm'

// ? section game - playing

import {
  playingScreenInit,
  playingScreenDestroy,
} from '../sections/game/playing'

// ? section game - wainting

import {
  waitingScreenInit,
  waitingScreenDestroy,
} from '../sections/game/waiting'

// import * as data from '../../../config/config.json' // ? this can import a config file is needed

// ? build our array form imported modules

const myModuleFuncs = {
  splashScreenInit,
  splashScreenDestroy,
  signinScreenInit,
  signinScreenDestroy,
  signupScreenInit,
  signupScreenDestroy,
  overviewScreenInit,
  overviewScreenDestroy,
  historyScreenInit,
  historyScreenDestroy,
  infoeditScreenInit,
  infoeditScreenDestroy,
  trainingScreenInit,
  trainingScreenDestroy,
  publicScreenInit,
  publicScreenDestroy,
  privateScreenInit,
  privateScreenDestroy,
  createScreenInit,
  createScreenDestroy,
  joinScreenInit,
  joinScreenDestroy,
  hallScreenInit,
  hallScreenDestroy,
  listScreenInit,
  listScreenDestroy,
  addScreenInit,
  addScreenDestroy,
  aboutgameScreenInit,
  aboutgameScreenDestroy,
  aboutteamScreenInit,
  aboutteamScreenDestroy,
  confirmScreenInit,
  confirmScreenDestroy,
  playingScreenInit,
  playingScreenDestroy,
  waitingScreenInit,
  waitingScreenDestroy,
}

const loadModule = (_active_view) => {
  if (document.body.dataset.module.length > 0) {
    myModuleFuncs[document.body.dataset.module + 'ScreenDestroy']()
  } // ? destroy any current modules if the data-module attribute form body has some content. This is always added below after module is loaded

  try {
    myModuleFuncs[_active_view + 'ScreenInit']() // ? Load the right module based on the _active_view which will reflect the final has for the section either deeplinking[0] or deeplinkin[1]
    document.body.dataset.module = _active_view // ? Set the active module as data-module into the html so in the next hash change we know what module we need to destroy
  } catch (err) {
    console.log(err)
  }
}

export const slideToView = (_hash) => {
  const hash = _hash.substring(2).split('#') // ? var to hold the original hash into an array excluding # chars
  let deeplinking = [] // ? new array to store clean hash parts

  hash.forEach((el) => {
    deeplinking.push(el.replace('/', '')) // ? Remove any / from the hash array and push it to the deeplinking array
  })

  // if (deeplinking.length > 2) {
  //   window.location.hash = ''
  //   return
  // } // ? If we are trying to load anything beyond 2 levels deep on the page. Not needed since it will return to home as soon as it enters the x level and no match is found on the page. This allow for even deeper levels of content inside the page for example #/singlegame/#/private/#/create

  const myMainSections = Array.from(
    document.querySelectorAll(
      'section.app div.section-holder div.holder-frame div.holder > div'
    )
  )

  if (
    document.body.classList.contains('logged') &&
    !document.body.classList.contains('playing') &&
    !document.body.classList.contains('waiting')
  ) {
    deeplinking.forEach((el, i) => {
      if (i == 0) {
        const myFirstLevelIndex = document.getElementById(deeplinking[i])

        if (!myFirstLevelIndex || myFirstLevelIndex.length < 1) {
          window.location.hash = ''
          return
        } // ? If we are trying to view an unexisting page just redirect to nothing and let index.js take care of

        myVerticalAppView.style.transform =
          'translateY(-' +
          myMainSections.indexOf(document.getElementById(deeplinking[i])) *
            100 +
          '%)'

        setActiveMainMenuLink(deeplinking[0]) // ? set the right menu state when hashchange is triggered

        // TODO handle 404

        // ? user is trying to access splash, sign in or sign up when already logged

        if (
          deeplinking[i] == 'splash' ||
          deeplinking[i] == 'signin' ||
          deeplinking[i] == 'signup'
        ) {
          window.location.hash = '' // ? set hash to nothing, let code from index.js to take care of redirection
          return
        } else if (deeplinking[i] == 'waiting') {
          // ! user is logged but trying to access waiting. check if user is commited to any game
          // ! we should destroy any mathces associated with the user
          // TODO for now we just redirect to home

          document.body.classList.remove('waiting')
          document.location.hash = ''
        }
      } else if (i == 1) {
        const mySecondarySections = Array.from(
          document
            .getElementById(deeplinking[0])
            .querySelectorAll("div[class*='slide']")
        )

        myAppSectionSlides.forEach((el, i) => {
          el.classList.remove('viewing')
        })

        // ? Try load 2 level of the site after first one. Anything that does not conform with match on an existing page node element redirects user to a blank hash handled by index.js

        try {
          const myChildTarget = document.querySelector('div#' + deeplinking[i])
          let myChildtargetIndex = mySecondarySections.indexOf(myChildTarget)

          if (myChildtargetIndex < 0) {
            window.location.hash = ''
            return
          } // ? If we are trying to access a non-existing page just redirect to nothing and let index.js take care of

          document.getElementById(deeplinking[0]).style.transform =
            'translateX(-' + myChildtargetIndex * 100 + '%)' // ? move the right view into slide

          document.getElementById(deeplinking[1]).classList.add('viewing')

          setActiveSubMenuLink(deeplinking[1]) // ? set the right menu state when hashchange is triggered
        } catch (err) {
          window.location.hash = ''
          return
        }

        try {
          loadModule(deeplinking[1]) // ? Use module for this particular page
          hideMobileMenu() // ? hide mobile menu if present
        } catch (err) {
          console.log(err)
        }
      }
    })

    try {
      // ? reveal string in header for sections

      const myTargetSection = document.querySelector(
        'section.app div.section-holder nav li.active ul li.current a'
      )

      if (myTargetSection) {
        revealStringByChar(
          myTargetSection.dataset.sectionTitle.toString(),
          myAppSectionTitleContainer,
          50
        )
      } // ? Check if we are trying to access an actual app section
    } catch (err) {
      console.log('cannot reveal header letters ', err)
    }
  } else if (document.body.classList.contains('playing')) {
    loadModule(deeplinking[0])
    return
  } else if (document.body.classList.contains('waiting')) {
    loadModule(deeplinking[0])
    return
  } else if (document.body.classList.contains('not-logged')) {
    // ? get to know if a div constains a class that starts with a given string

    myLoginSection.classList.remove('showing-signin', 'showing-signup') // ? remove default classes in case we are showing splash again

    switch (deeplinking[0]) {
      case 'splash':
        break
      case 'signin':
        myLoginSection.classList.add('showing-signin')
        // ? Load sign in form script
        break
      case 'signup':
        myLoginSection.classList.add('showing-signup')
        // ? load sign up from script
        break
      default:
        window.location.hash = '' // TODO handle 404
    }

    try {
      loadModule(deeplinking[0]) // ? Use module for this particular page
      hideMobileMenu() // ? hide mobile menu if present
    } catch (err) {
      console.log(err)
    }
  }
}
