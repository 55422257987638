import 'bootstrap-icons/font/bootstrap-icons.scss' // ? import icons font
import Fetcher from './classes/Fetcher'
import WebSocketCon from './classes/WebSockCon'
import { host_device_ip, nginx_port } from '../config/device_config'

import { slideToView } from './modules/display/view'
import {
  myMainNav,
  myUsernameMenuText,
  myNavMobileButton,
  myMenuAvatar,
} from './globals/dom'
import gameSettings from '../config/game'

import {
  showMobileMenu,
  hideMobileMenu,
} from './modules/interactive/interactive_nav'

import { handleQueryParameters } from './modules/sections/loginsection/register42'

import {
  setTogglePasswordVisibility,
  removeQueryString,
} from './modules/utils/utils'

// ? Classes
const fetcher = new Fetcher()
export const wsOnline = new WebSocketCon()
export const nginx_https_host = `https://${host_device_ip}:${nginx_port}`
export const nginx_wss_host = `wss://${host_device_ip}:${nginx_port}`
/**
 *
 * @param {*} e This is the DOM event diaptched for the Browser API
 */

const addInteractions = () => {
    // * Listen for clicks on the mobile menu button - toogle menu on mobile

    myNavMobileButton.addEventListener('click', (e) => {
      if (!myMainNav.classList.contains('showing-mobile')) {
        showMobileMenu()
      } else {
        hideMobileMenu()
      }
    })

    // ? Add functionality for all passwords input fields on the page so text can be seen by user on icon click
    // ? The function from utils will take care of everthing except for reseting the fields on every [whatever]ScreenInit() function. That has been added on every script file separately
    setTogglePasswordVisibility(
      document.querySelectorAll('div.password-field-container')
    )
  },
  deeplinking = (e) => {
    // ? If the user has NO access we add the .not-logged class, if they do we add the .logged class after we determine results from backend.

    // ! important before changing any hash to determine the .not-logged or .logged class for the body

    let userIsLogged = false // Make this variable global

    if (localStorage.getItem('transcendence')) {
      document.body.classList.remove('not-logged')
      document.body.classList.add('logged')

      userIsLogged = true

      // * We set up here the username for the menu

      myUsernameMenuText.textContent = JSON.parse(
        localStorage.getItem('transcendence')
      ).username

      if (wsOnline) wsOnline.ws_init(myUsernameMenuText.textContent)
      else console.error('wsOnline is', wsOnline)

      gameSettings.selfName = myUsernameMenuText.textContent

      myMenuAvatar.style.backgroundImage =
        'url(' +
        nginx_https_host +
        JSON.parse(localStorage.getItem('transcendence')).avatar +
        ')'
    } else {
      if (wsOnline) wsOnline.ws_close()
      document.body.classList.remove('logged')
      document.body.classList.add('not-logged')
      userIsLogged = false

      if (window.location.search.length > 0) {
        let query_params = new URLSearchParams(window.location.search)

        removeQueryString()
        if (query_params.size > 0) handleQueryParameters(query_params)
        return
      }
    }

    if (window.location.hash == '') {
      if (userIsLogged) {
        if (!document.body.classList.contains('playing')) {
          // ? Normal redirection when user is just browsing the page
          window.location.hash = '/profile/#/overview'
        } else {
          // TODO What happens when a user refresh the page when in the playing screen?
          // TODO We need to decide what we allow from user if that happens
          window.location.hash = '/playing/'
        }
      } else {
        // ? Regular redirection when user is no logged, just go to signin / sign up screens
        window.location.hash = '/splash/'
      }
      return
    }

    // ? Redirect to page
    slideToView(window.location.hash)
  },
  init = (e) => {
    document.removeEventListener('DOMContentLoaded', init, false)

    window.addEventListener('hashchange', deeplinking, false)
    const deeplinkingEvent = new CustomEvent('hashchange', {
      detail: 'hashchange',
    }) // * Create the event

    window.dispatchEvent(deeplinkingEvent) // ? Trigger the event

    addInteractions()
  }

document.addEventListener('DOMContentLoaded', init, false)
