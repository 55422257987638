import { ethers } from 'ethers'
import abi from '../../contract/abi.json'
import { CONTRACT_ADDRESS } from '../../globals/dom'

let provider, contract

export const setEthers = () => {
   provider = new ethers.providers.Web3Provider(window.ethereum)
   contract = new ethers.Contract(CONTRACT_ADDRESS, abi, provider)
 },
 getAccounts = async () => {
   return await window.ethereum.request({ method: 'eth_requestAccounts' })
 },
 storeTournament = async (_tournamentName, _games) => {
   const signer = provider.getSigner()
   const signerAddress = await signer.getAddress()

   try {
     const txRes = await contract
       .connect(signer)
       .createAndSubmitGames(_tournamentName, _games)

     await txRes.wait()

     return txRes.hash
   } catch (err) {
     console.log(err)
     return ''
   }
 }
