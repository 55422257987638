import Toaster from '../../../../classes/Toaster'
import Fetcher from '../../../../classes/Fetcher'
import gameSettings from '../../../../../config/game'
import { myWaitingRoomInfoText } from '../../../../globals/dom'
import { nginx_https_host } from '../../../..'
import {
  resetTournInGameSettings,
  resetGameInGameSettings,
  goToWaiting,
} from '../../game/waiting'

let myCreateTournamentForm,
  myInputField,
  myRangeSelectors,
  mySubmitButton,
  myToaster,
  myFetcher

const onSubmitButton = (e) => {
  e.preventDefault()

  let data = {}

  if (myInputField.value.length == 0) {
    myToaster = new Toaster('Please do not leave empty fields')
    return
  } // ? Do not allow empty tournament name

  data['name'] = myInputField.value // ? Set the value for the tournament name

  // TODO validate tournament name

  let isFormValid = true

  if (!/^[-\w]{4,15}$/.test(data['name'])) {
    myToaster = new Toaster(
      '<div><b>Tournament name requeriments</b></div><ul style="list-style:none;padding:0;margin:1rem 0 0 0"><li>Between 4 and 15 characters</li><li>Letters are valid</li><li>Numbers are valid</li><li>_ and - are also valid</li><li>No spaces allowed</li></ul>'
    )
    isFormValid = false
    return
  }

  myRangeSelectors.forEach((el, i) => {
    switch (el.name) {
      case 'tournament_game_players':
        data['players'] = parseInt(
          el.parentNode.querySelector('h4 span').textContent
        )
        break
      case 'tournament_game_points':
        data['points'] = parseInt(
          el.parentNode.querySelector('h4 span').textContent
        )
        break
    }
  })

  myToaster ? myToaster.closeToaster() : null

  resetTournInGameSettings()
  resetGameInGameSettings()
  let url = nginx_https_host + '/api/scheduling/tournament/create/'
  myFetcher.postAuthData(url, data).then((res) => {
    //? Make json into object with key pair values
    const obkeys = Object.keys(res)

    if (obkeys.includes('errormsg')) {
      // ? If the response includes the error object
      myToaster = new Toaster(
        '<p style="padding:0 0.5rem 0 0;">There is already a tournament with that name, please choose another one.</p>'
      )
    } else {
      myToaster = new Toaster('Tournament created with id ' + res.id, 1500)
      gameSettings.tournament = String(res.name)

      myWaitingRoomInfoText.innerHTML = `<h4>You created the tournament ${gameSettings.tournament}</h4>`

      gameSettings.disablePlay = true
      gameSettings.needDisablePlayNow = true
      gameSettings.leave_ack = false
      goToWaiting()
    }
  })
}

const onSlideChange = (e) => {
  if (e.target.id == 'tournament_game_players') {
    switch (parseInt(e.target.value)) {
      case 2:
        e.target.parentNode.querySelector('h4 span').textContent = 2
        break
      case 4:
        e.target.parentNode.querySelector('h4 span').textContent = 4
        break
      case 6:
        e.target.parentNode.querySelector('h4 span').textContent = 8
        break
      case 8:
        e.target.parentNode.querySelector('h4 span').textContent = 16
        break
      case 10:
        e.target.parentNode.querySelector('h4 span').textContent = 32
        break
      default:
        null
    }
  } else {
    e.target.parentNode.querySelector('h4 span').textContent = e.target.value
  }
}

export const createScreenInit = () => {
  myCreateTournamentForm = document.getElementById('create-tournament-form')

  myInputField = myCreateTournamentForm.querySelector('input[type="text"]') // ? Get the text inputs

  myRangeSelectors = Array.from(
    myCreateTournamentForm.querySelectorAll('input[type="range"]') // ? Get the text inputs
  )

  // * Reset values for DOM input fields

  myInputField.value = ''

  myRangeSelectors.forEach((el, i) => {
    if (el.id == 'tournament_game_players') {
      el.value = el.parentNode.querySelector('h4 span').textContent = 2
    } else {
      el.value = el.parentNode.querySelector('h4 span').textContent = 1
    }
  })

  myRangeSelectors.forEach((el, i) => {
    el.addEventListener('input', onSlideChange, false)
  })
  mySubmitButton = myCreateTournamentForm.querySelector('button')

  // ? listen clicks on the send button
  mySubmitButton.addEventListener('click', onSubmitButton, false)

  myFetcher = new Fetcher()
}

export const createScreenDestroy = () => {
  myRangeSelectors.forEach((el, i) => {
    el.removeEventListener('input', onSlideChange, false)
  })

  mySubmitButton.removeEventListener('click', onSubmitButton, false)

  myCreateTournamentForm =
    myInputField =
    myRangeSelectors =
    mySubmitButton =
    myToaster =
    myFetcher =
      null
}
